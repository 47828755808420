import React from "react";
import Theme from "../theme/theme_2";
import EntryExsitLog from "../components/EntryExsitLog";
function entryExsitManagement() {
  return (
    <div>
      <Theme PageName={"Parking Entry Exit Log"}>
        <EntryExsitLog />
      </Theme>
    </div>
  );
}export default entryExsitManagement;
