import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { checkPermission } from '../utills/checkPermission';
import styled from 'styled-components';
import theme from '../theme';
import { Layout, Menu } from 'antd';
import {
  faCircle,
  faUserPlus,
  faHome,
  faUsers,
  faUserCog,
  faFileMedicalAlt,
  faCar,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBar from '../components/common/NavBar';
import HeadBar from '../components/common/HeadBar';
import LogoTheme1 from '../assets/images/logo_ths.png';

const { SubMenu } = Menu;
const { Content } = Layout;

const Wrapper = styled.div`
  padding: 20px;
  background: ${(props) => props.theme.backgroundColor};
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.primaryColor};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  margin-right: 10%;
`;

export default function theme_2(props) {
  const MenuItem = () => {
    const location = useLocation();
    return (
      <>
        <img className="theme-2-logo" src={LogoTheme1} />
        <Menu
          theme="white"
          mode="inline"
          activeKey={location.pathname}
          selectedKeys={location.pathname}
        >
          {checkPermission("8") ? (true) : (checkPermission("1") ? (true) : (checkPermission("2") ? (true) : (checkPermission("4")))) &&
            <Menu.Item
              key="/project"
              icon={<Icon icon={faHome} />}
              className="side-font"
            >
              <NavLink exact to="/project">
                Project
              </NavLink>
            </Menu.Item>
          }
          {checkPermission("4") &&
            <Menu.Item
              key="/registernewuser"
              icon={<Icon icon={faUserPlus} />}
              className="side-font"
            >
              <NavLink exact to="/registernewuser">
                Register New User
              </NavLink>
            </Menu.Item>
          }
          {checkPermission("2") &&
            <Menu.Item
              key="/invite-visitor"
              icon={<Icon icon={faUserPlus} />}
              className="side-font"
            >
              <NavLink exact to="/invite-visitor">
               Seminar Visitor Invite
              </NavLink>
            </Menu.Item>
          }
          <SubMenu
            key="/management"
            icon={<Icon icon={faUserCog} />}
            title="Management"
            className="side-font"
          > {checkPermission("8") ? (true) : (checkPermission("1") ? (true) : (checkPermission("2") ? (true) : (checkPermission("4")))) &&
            <Menu.Item
              icon={<Icon icon={faCircle} fontSize="10px" />}
              key="/management/users"
              className="side-font"
            >
              <NavLink exact to="/management/users">
                User
              </NavLink>
            </Menu.Item>
            }
            {checkPermission("2") &&
              <Menu.Item
                key="/parking/cars"
                icon={<Icon icon={faCircle} />}
                className="side-font"
              >
                <NavLink exact to="/parking/cars">
                  Car
                </NavLink>
              </Menu.Item>
            }
            {(checkPermission("1") ? (checkPermission("1")) : (checkPermission("2"))) &&
              <Menu.Item
                icon={<Icon icon={faCircle} fontSize="10px" />}
                key="/management/consent"
                className="side-font"
              >
                <NavLink exact to="/management/consent">
                  Consent
                </NavLink>
              </Menu.Item>
            }
            {(checkPermission("2") ? (checkPermission("2")) : (checkPermission("4"))) &&
              <Menu.Item
                icon={<Icon icon={faCircle} fontSize="10px" />}
                key="/management/organization"
                className="side-font"
              >
                <NavLink exact to="/management/organization">
                  Organization
                </NavLink>
              </Menu.Item>
            }
            {checkPermission("2") &&
              <Menu.Item
                icon={<Icon icon={faCircle} fontSize="10px" />}
                key="/management/reception"
                className="side-font"
              >
                <NavLink exact to="/management/reception">
                  Reception
                </NavLink>
              </Menu.Item>
            }
            {checkPermission("2") &&
              <Menu.Item
                icon={<Icon icon={faCircle} fontSize="10px" />}
                key="/management/newsFeed"
                className="side-font"
              >
                <NavLink exact to="/management/newsFeed">
                  News Feed
                </NavLink>
              </Menu.Item>
            }
            {checkPermission("2") &&
              <Menu.Item
                icon={<Icon icon={faCircle} fontSize="10px" />}
                key="/management/promotion"
                className="side-font"
              >
                <NavLink exact to="/management/promotion">
                  Promotion
                </NavLink>
              </Menu.Item>
            }
          </SubMenu>
          {checkPermission("4") &&
              <Menu.Item
                key="/parking/e-stamp"
                icon={<Icon icon={faCar} />}
                className="side-font"
              >
                <NavLink exact to="/parking/e-stamp">
                  E-stamp
                </NavLink>
              </Menu.Item>
            }
          {checkPermission("2") &&
            <Menu.Item
              key="/request_approval"
              icon={<Icon icon={faUsers} />}
              className="side-font"
            >
              <NavLink exact to="/request_approval">
                Request Approval
              </NavLink>
            </Menu.Item>
          }{(checkPermission("1") ? (checkPermission("1")) : (checkPermission("2"))) &&
           <SubMenu
            key="/Log"
            icon={<Icon icon={faFileMedicalAlt} />}
            title="Log"
            className="side-font"
          >
          {(checkPermission("1") ? (checkPermission("1")) : (checkPermission("2"))) &&
            <Menu.Item
              icon={<Icon icon={faFileMedicalAlt} />}
              key="/building-log"
              className="side-font"
            >
              <NavLink exact to="/building-log">
                Building Log
              </NavLink>
            </Menu.Item>
          }
          {(checkPermission("1") ? (checkPermission("1")) : (checkPermission("2"))) &&
            <Menu.Item
              icon={<Icon icon={faFileMedicalAlt} />}
              key="/entry-exit"
              className="side-font"
            >
              <NavLink exact to="/entry-exit">
                Entry Exit Log
              </NavLink>
            </Menu.Item>
          }
          {(checkPermission("1") ? (checkPermission("1")) : (checkPermission("2"))) &&
            <Menu.Item
              icon={<Icon icon={faFileMedicalAlt} />}
              key="/webStamp"
              className="side-font"
            >
              <NavLink exact to="/webStamp">
              Web Stamp Log
              </NavLink>
            </Menu.Item>
          }
          </SubMenu>}
          {checkPermission("2") &&
            <Menu.Item
              key="/view-report"
              icon={<Icon icon={faFilePdf} />}
              className="side-font"
            >
              <NavLink exact to="/view-report">
              View Report
              </NavLink>
            </Menu.Item>
          }
        </Menu>
      </>
    );
  };
  return (
    <div>
      <Layout>
        <NavBar className="Theme_2-Side" MainMenu={MenuItem} />
        <Layout
          style={{ minHeight: '100vh', background: theme.backgroundColor }}
        >
          <Wrapper>
            <HeadBar MainMenu={MenuItem} PageName={props.PageName} />
            <Content className="theme-2-content">{props.children}</Content>
          </Wrapper>
        </Layout>
      </Layout>
    </div>
  );
}
