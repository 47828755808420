import React, { useState, useContext } from 'react';
import AuthenContext from '../../utills/useAuthentication';
import get from 'lodash/get';
import { startOfToday, getDate, getYear } from 'date-fns';
import styled, { css } from 'styled-components';
import theme from '../../theme';
import { Drawer, Button, Space, Row, Col } from 'antd';
import { Icon, HeadBarAvatar, LanguageSelector } from '../../components';
import {
  faClock,
  faTimes,
  faBars,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


var startOfDay = startOfToday();
var date = getDate(startOfDay).toString();
var month = startOfDay.toString().substring(4, 7);
var year = getYear(startOfDay).toString();
var day = date + '\xa0' + month + '\xa0' + year;

const baseShadow = css`
box-shadow: 0px 2px 10px rgb(0 89 83);
  
`;

const Card = styled.div`
  ${baseShadow}
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background: ${(props) => props.theme.white};
  border-radius: 10px;
`;


export default function HeadBar({ MainMenu, PageName }) {

  const { user,logout } = useContext(AuthenContext);
  const [visible, setVisible] = useState(false);

  const Header = () => {
    return (
      <Row align="middle" justify="space-around">
        <Col xxl={0} xl={0} lg={0} md={2} sm={2} xs={3}>
          <Button
            type="primary"
            icon={<Icon color="white" icon={faBars} id='faBars' />}
            onClick={() => setVisible(true)}
          />
        </Col>
        <Col xxl={8} xl={6} lg={5} md={6} sm={8} xs={10}>
          <p className="heabar-font" style={{ textAlign: 'center' }}>
            {PageName}
          </p>
        </Col>
        <Col xxl={4} xl={4} lg={4} md={4} sm={0} xs={0}>
          <Card size="small" bordered={false} style={{ background: "#E86D1F" }}>
            <Space>
              <Icon icon={faClock} id='faClock' />
              <p className="heabar-font">{day}</p>
            </Space>
          </Card>
        </Col>
        <Col xxl={0} xl={0} lg={0} md={0} sm={0} xs={4}>
          <HeadBarAvatar />
        </Col>
        <Col xxl={6} xl={8} lg={10} md={6} sm={7} xs={0}>
          <Card size="small" bordered={false} style={{ background: "#E86D1F" }}>
            <Space>
              <span className="heabar-font">
                Hello,{' '}
                {get(user, 'data.firstname') ? get(user, 'data.firstname') : 'Admin'}
                {' '}
                {get(user, 'data.lastname') ? get(user, 'data.lastname') : 'Admin'}
              </span>
              <HeadBarAvatar />
            </Space>
          </Card>
        </Col>
        <Col
          xxl={2}
          xl={2}
          lg={2}
          md={3}
          sm={3}
          xs={0}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <Header />
      <Drawer
        closeIcon={<FontAwesomeIcon icon={faTimes} />}
        placement="left"
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <MainMenu />
      </Drawer>
    </div>
  );
}
