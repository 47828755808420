import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { api } from '../services';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import theme from '../theme';
import { Row, Col, Button, Input, Space, Tooltip } from 'antd';
import { Card, CardTable, Icon } from '../components';
import { faEye, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { UserAvatar } from './UserAvatar';
import { ApprovedModal } from './ApproveModal';
import Time from 'react-time-format'
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);
const cookies = new Cookies();

export default function ApprovalRequest() {
  const history = useHistory();
  const [dataNew, setDataNew] = useState();
  const [dataFormatImg, setDataNewImg] = useState(0);
  const [totalData, setTotalData] = useState();
  const [searchCol, setSearchCol] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState("");

  const fetchUser = (limit, offset , search) => {
    if(search) setPage(1);
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/approvals?limit=${limit}&offset=${offset}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          setSearchCol("");
          setSearchData("");
          formatData( sortBy(get(res, 'data.data.list'), ['created_at']).reverse());
          setTotalData(get(res, 'data.data'))
          setDataNew(
            sortBy(get(res, 'data.data.list'), ['created_at']).reverse()
          );
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };

  const searchFilter = (limit, offset , search , col , data) => {
    if(search) setPage(1);
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    let option = {}
    if(col === "email")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&email=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    else if(col === "phone")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&phone=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    else if(col === "firstname")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&fname=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    else if(col === "lastname")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&lname=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setSearchCol(col);
          setSearchData(data);
          formatData( sortBy(get(res, 'data.data.list'), ['created_at']).reverse());
          setTotalData(get(res, 'data.data'))
          setDataNew(
            sortBy(get(res, 'data.data.list'), ['created_at']).reverse()
          );
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };

  const handleChange = (pagination) => {
    setPage(pagination.current);
    const offset = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    if(searchCol === "") fetchUser(limit,offset,false);
    else searchFilter(limit, offset , false , searchCol , searchData)
  };

  useEffect(() => {
    fetchUser(10,0,true);
  }, []);

  const setRole = (data) => {
    if (data === 1) {
      return 'Project Owner';
    } else if (data === 2) {
      return 'Juristic Admin';
    } else if (data === 3) {
      return 'Reception';
    } else if (data === 4) {
      return 'Organize Admin';
    } else if (data === 5) {
      return 'Member';
    } else if (data === 6) {
      return 'Visitor';
    } else if (data === 7) {
      return 'Walk-in Visitor';
    } else if (data === 8) {
      return 'Seminar Visitor';
    }
    
  };

  const formatData = (data) => {
    const dataFormat = data.map((user) => {
      return {
          portrait_photo: user.detail,  
      };
    });
    setDataNewImg(dataFormat);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchCol === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchData]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, dataIndex) => {
    searchFilter(10,0,true,dataIndex,selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    fetchUser(10,0,true);
    clearFilters();
  };

  const formatDate = (issued_datetime) => {
    issued_datetime = issued_datetime * 1000
    let d = new Date(issued_datetime);

    return d.getTime()
  }

  const columns = [
    {
        title: 'Timestamp',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'start',
        className: 'theme-2-table-text',
        responsive: ['sm'],
        render: (issued_datetime) => (
          <div>
            <Time value={formatDate(issued_datetime)} format="DD/MM/YYYY hh:mm:ss" />
          </div>
        ),
    },
    {
      title: 'Firstname',
      dataIndex: 'firstname',
      key: 'firstname',
      align: 'start',
      className: 'theme-2-table-text',
      ...getColumnSearchProps('firstname'),
      render: (id, dataNew) => {
        return (
          <Space>
            <UserAvatar
              imgWidth="30px"
              fontSize="30px"
              imageUrl={dataNew.portrait_photo}
            />
            {get(dataNew, 'firstname')}
          </Space>
        );
      },
    },
    {
      title: 'Lastname',
      dataIndex: 'lastname',
      key: 'lastname',
      align: 'start',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      ...getColumnSearchProps('lastname'),
    },{
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'start',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Type',
      dataIndex: 'transaction_type',
      key: 'transaction_type',
      align: 'start',
      className: 'theme-2-table-text',
      responsive: ['sm'],
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      className: 'theme-2-table-text',
      render: (dataNew) => (
        <Space size="middle">
        <Tooltip title="View" color={theme.primaryColor}>
            <Button
              icon={<Icon icon={faEye} />}
              type="text"
              onClick={() => history.push(
                {
                  pathname:  `/request_approval/view_request`,
                  state: { detail: dataNew }
               })}
            />
          </Tooltip>
          <Tooltip title="Approve" color={theme.primaryColor}>
            <Button
              icon={<Icon icon={faCheck} />}
              type="text"
              onClick={() => {
                ApprovedModal('request', dataNew, 'approve' , fetchUser, '');
              }}
            />
          </Tooltip>
          <Tooltip title="Reject" color={theme.primaryColor}>
            <Button
              icon={<Icon icon={faTimes} />}
              type="text"
              onClick={() => {
                ApprovedModal('request', dataNew, 'reject' , fetchUser, '');
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col span={24}>
                <div style={{ marginTop: '10px' }}>
                  <p className="theme-2-text-1">{'All Request \xa0'}</p>
                  <p className="theme-2-text-2">
                    {get(totalData,"total") ? get(totalData,"total") : 0}
                    {'\xa0Total user'}
                  </p>
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <CardTable
                  rowKey="id"
                  columns={columns}
                  dataSource={dataNew}
                  bordered={false}
                  onChange={handleChange}
                  pagination={{
                    current: page,
                    position: ['bottomCenter'],
                    total: get(totalData,"total"),
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
