import axios from "axios";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
const cookies = new Cookies();
const swal = withReactContent(Swal);

let details = {
  "client_id":'thaisummit-client',
  "client_secret":'20bd501c-7276-4c1f-a687-b08d8779174e',
  "grant_type":'refresh_token',
  "refresh_token" : cookies.get("refreshToken")
};

let formBody = [];
for (let property in details) {
let encodedKey = encodeURIComponent(property);
let encodedValue = encodeURIComponent(details[property]);
formBody.push(encodedKey + "=" + encodedValue);
}
formBody = formBody.join("&");
const getNewTokenOption = {
  method: "post",
  url: `${process.env.REACT_APP_API_KEYLOG}/token`,
  headers:{
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  data:formBody
};

axios.interceptors.response.use(null, (error) => {
  if (
    error.config &&
    error.response?.status === 501 &&  //error.response?.status === 401 &&  Use the status code your server returns when token has expired
    !error.config.__isRetry
  ) {
    return new Promise((resolve, reject) => {
      refreshToken(axios, error.config)
        .then((result) => {
          resolve(result);
          cookies.set('expiresAtTime', true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  else{
    cookies.set('expiresAtTime', true);
    return Promise.reject(error);
  }
  
});

const storeToken = (data) => {
  var date = new Date();
      date.setTime(date.getTime() + (60*60*24*365));
  const accessToken = data.access_token;
  const refreshToken = data.refresh_token;
  cookies.set("accessToken", accessToken, {
    path: "/",
    expires:date,
  });
  cookies.set("refreshToken", refreshToken, {
    path: "/",
    expires: date,
  });
};

const refreshToken = (axios, config) => {
  return new Promise((resolve, reject) => {
    api() // Endpoint to request new token
      .then((res) => {
        storeToken(res); // Store new token locally (Local storage or cookies)
        config.headers.Authorization = `Bearer ${res.data.access_token}`; // Attach the new token to the headers
        axios
          .request(config) // Repeat the initial request
          .then((result) => {
            cookies.set('expiresAtTime', true);
            return resolve(result);
          })
          .catch((err) => {
            cookies.set('expiresAtTime', true);
            return reject(err);
          });
      })
      .catch((err) => {
        cookies.set("lastUrl", window.location.href, { path: "/" });
        cookies.set('expiresAtTime', true);
        cookies.remove("accessToken", { path: "/" });
        cookies.remove("refreshToken", { path: "/" });
        cookies.remove("permission", { path: "/" });
        cookies.remove("expiresAt", { path: "/" });
        localStorage.removeItem("avatar");
        window.location.reload();
      });
  });
};

export const api = (options = getNewTokenOption) => {
  return axios({
    headers: {
      Authorization: `Bearer ${cookies.get("accessToken")}`,
    },
    ...options,
  });
};
