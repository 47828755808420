import React  , { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import styled from 'styled-components';
import { Row, Col, Button, Form, Input, Card as BaseCard, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { InputAvatar } from './InputAvatar';
import SkeletonImage from 'antd/lib/skeleton/Image';
import Cookies from 'universal-cookie';

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

const cookies = new Cookies();

export const UpdateProfileForm = ({
  userID,
  submitForm,
  initialValue,
  setImg1,
  manageAccess,
  type_from,
  initialValueParent,
  iCheckParent
}) => {
  const history = useHistory();
  const [image, setImage] = useState();
  const [isChange, setIsChange] = useState(false);
  const [updateProfileForm] = Form.useForm();
  let isuser_type = false;
  let isuser_type_user = false;
  let isuser_permission = cookies.get('permission');
console.log(initialValue);
  if (isuser_permission) {
     isuser_type = true;
  }
   if (isuser_permission == 2 && type_from == "manageUser" && (initialValue.user_type == 4)) {
    isuser_type_user = false;
   }
   else if (isuser_permission == 4 && type_from == "manageUser" && (initialValue.user_type == 5)) {
    isuser_type_user = false;
   }
   else{
    isuser_type_user = true;
   }
  
  const checkIsChange = (e , field) => {
    if(field == 'firstname')
    {
      if(initialValue.firstname != e) setIsChange(true);
      else setIsChange(false);
    }
    else if (field == 'lastname')
    {
      if(initialValue.lastname != e) setIsChange(true);
      else setIsChange(false);
    }
    else if (field == 'remark')
    {
      if(initialValue.remark != e) setIsChange(true);
      else setIsChange(false);
    }
    else
    {
      if(initialValue.phone != e) setIsChange(true);
      else setIsChange(false);
    }
  }

  const labelCol = {
    updateprofile: {
      xs: { span: 24 },
      sm: { span: 7 },
      md: { span: 7 },
      lg: { span: 7 },
      xl: { span: 7 },
      xxl: { span: 5 },
    },
    manageAccess: {
      xs: { span: 24 },
      sm: { span: 7 },
      md: { span: 10 },
      lg: { span: 9 },
      xl: { span: 8 },
      xxl: { span: 8 },
    },
  };

  const wrapperCol = {
    updateprofile: {
      xs: { span: 24 },
      sm: { span: 17 },
      md: { span: 18 },
      lg: { span: 20 },
      xl: { span: 20 },
      xxl: { span: 18 },
    },
    manageAccess: {
      xs: { span: 24 },
      sm: { span: 17 },
      md: { span: 18 },
      lg: { span: 20 },
      xl: { span: 20 },
      xxl: { span: 18 },
    },
  };

  const setImg = (url) => {
    setImage(url);
    setImg1(url)
    if(initialValue.portrait_photo != url) setIsChange(true);
    else setIsChange(false);
  }
  
  return (
    <Form
      name="updateProfileForm"
      form={updateProfileForm}
      labelAlign="left"
      onFinish={submitForm}
      labelCol={manageAccess ? labelCol.manageAccess : labelCol.updateprofile}
      wrapperCol={
        manageAccess ? wrapperCol.manageAccess : wrapperCol.updateprofile
      }
      initialValues={{
        email: get(initialValue, 'email'),
        firstName: get(initialValue, 'firstname'),
        lastName: get(initialValue, 'lastname'),
        telephone: get(initialValue, 'phone'),
        citizenId: get(initialValue, 'personal_id'),
        profilePicture: get(initialValue,'portrait_photo'),
        remark: get(initialValue,'remark')
      }}
    >
      <Card
        title="User Profile"
        extra={
          <Row> 
            {isuser_type && type_from == "profile" && (
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <Form.Item style={{ paddingTop: '10%' }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ marginRight: '10px' }}
                        />
                      }
                      onClick={() => history.goBack()}
                    >
                      Back
                    </Button>
                    {isChange && (
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="theme-button-save"
                        icon={
                          <FontAwesomeIcon
                            icon={faSave}
                            style={{ marginRight: '10px' }}
                          />
                        }
                      >
                        Save
                      </Button>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            )}
            {isuser_type && type_from == "profile" && (
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <Form.Item style={{ paddingTop: '35%' }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                      onClick={() => history.goBack()}
                    ></Button>
                    {isChange && (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="theme-2-button-1"
                        icon={<FontAwesomeIcon icon={faSave} />}
                      ></Button>
                    )}
                    
                  </Space>
                </Form.Item>
              </Col>
            )}
            { type_from == "manageUser" && isuser_permission == 4  && (initialValue.user_type != 6 && initialValue.user_type != 7 && initialValue.user_type != 8) && (
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <Form.Item style={{ paddingTop: '10%' }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ marginRight: '10px' }}
                        />
                      }
                      onClick={() => history.goBack()}
                    >
                      Back
                    </Button>
                    {isChange && (
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="theme-button-save"
                        icon={
                          <FontAwesomeIcon
                            icon={faSave}
                            style={{ marginRight: '10px' }}
                          />
                        }
                      >
                        Save
                      </Button>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            )}
            { type_from == "manageUser" && isuser_permission == 4 && (initialValue.user_type != 6 && initialValue.user_type != 7 && initialValue.user_type != 8) && (
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <Form.Item style={{ paddingTop: '35%' }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                      onClick={() => history.goBack()}
                    ></Button>
                    {isChange && (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="theme-2-button-1"
                        icon={<FontAwesomeIcon icon={faSave} />}
                      ></Button>
                    )}
                    
                  </Space>
                </Form.Item>
              </Col>
            )}
            { isuser_permission == 2 && initialValue.user_type == 4 && (
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <Form.Item style={{ paddingTop: '10%' }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          style={{ marginRight: '10px' }}
                        />
                      }
                      onClick={() => history.goBack()}
                    >
                      Back
                    </Button>
                    { isChange && (
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="theme-button-save"
                        icon={
                          <FontAwesomeIcon
                            icon={faSave}
                            style={{ marginRight: '10px' }}
                          />
                        }
                      >
                        Save
                      </Button>
                    )}
                    
                  </Space>
                </Form.Item>
              </Col>
            )}
            { isuser_permission == 2 && initialValue.user_type == 4 && (
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <Form.Item style={{ paddingTop: '35%' }}>
                  <Space>
                    <Button
                      type="default"
                      className="theme-2-button-2"
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                      onClick={() => history.goBack()}
                    ></Button>
                    {isChange && (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="theme-2-button-1"
                        icon={<FontAwesomeIcon icon={faSave} />}
                      ></Button>
                    )}
                    
                  </Space>
                </Form.Item>
              </Col>
            )}
          </Row>
          
        }
      >
        <Row justify="center">
          <Col
            xxl={manageAccess ? 16 : 12}
            xl={manageAccess ? 20 : 12}
            lg={manageAccess ? 24 : 16}
            md={manageAccess ? 24 : 16}
            sm={24}
            xs={24}
          >
            <div class="parent-user">
            <Row>
              <Col span={24}>
                <Form.Item label="Profile Picture" name="profilePicture">
                  <InputAvatar
                    onChangeFn={setImg}
                    imageUrl={image ? image : initialValue.portrait_photo}
                    isuserPermission={initialValue}
                    userType = {cookies.get('permission')}
                  />
                </Form.Item>
              </Col>
            </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                      {
                        type: 'email',
                        message: 'Your email is incorrect.',
                      },
                      {
                        min: 8,
                        message: 'minimum length = 8',
                      },
                      {
                        max: 165,
                        message: 'maximum length = 165',
                      },
                    ]}
                  >
                    <Input
                      disabled
                      placeholder="Email Address"
                      style={{
                        width: '100%',
                        color:'#000',
                        cursor: 'not-allowed',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Citizen ID"
                  name="citizenId"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your citizenID!',
                    },
                  ]}
                >
                  <Input
                    readOnly
                    placeholder="Citizen ID"
                    style={{
                      width: '100%',
                      cursor: 'not-allowed',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your firstname!',
                    },
                  ]}
                >
                  <Input readOnly={isuser_type_user} placeholder="First Name" style={!isuser_type_user ?{ width: '100%' }: { width: '100%',
                      cursor: 'not-allowed',
                      backgroundColor: 'rgba(0,0,0,0.1)', }} onChange={(e) => checkIsChange(e.target.value , 'firstname')}/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your lastname!',
                    },
                  ]}
                >
                  <Input readOnly={isuser_type_user} placeholder="Last Name" style={!isuser_type_user ?{ width: '100%' }: { width: '100%',
                      cursor: 'not-allowed',
                      backgroundColor: 'rgba(0,0,0,0.1)', }} onChange={(e) => checkIsChange(e.target.value , 'lastname')}/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Telephone"
                  name="telephone"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your telephone number!',
                    },
                    {
                      pattern: '^[+]?[0-9]{9,12}$',
                      message: 'Your telephone number is incorrect format.',
                    },
                  ]}
                >
                  <Input readOnly={isuser_type_user} placeholder="Telephone" style={!isuser_type_user ?{ width: '100%' }: { width: '100%',
                      cursor: 'not-allowed',
                      backgroundColor: 'rgba(0,0,0,0.1)', }} onChange={(e) => checkIsChange(e.target.value , 'phone')}/>
                </Form.Item>
              </Col>
            </Row>
            { get(initialValue,'remark') != null &&
              <Row>
              <Col span={24}>
                <Form.Item
                  label="Remark"
                  name="remark"
                >
                  <Input 
                    readOnly 
                    value={get(initialValue,'remark')} 
                    style={{
                      width: '100%',
                      cursor: 'not-allowed',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                    }} />
                </Form.Item>
              </Col>
            </Row>
            }
            </div>
           
            {iCheckParent && ( 
             <div>
            <Row>
              <Col span={24}>
                <h1>Parent user</h1>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="E-mail"
                >
                  <Input
                    readOnly
                    value={get(initialValueParent,'email')}
                    placeholder="E-mail"
                    style={{
                      width: '100%',
                      cursor: 'not-allowed',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="First name"
                >
                  <Input
                    readOnly
                    value={get(initialValueParent,'firstname')}
                    placeholder="First name"
                    style={{
                      width: '100%',
                      cursor: 'not-allowed',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Last name"
                >
                  <Input
                    readOnly
                    value={get(initialValueParent,'lastname')}
                    placeholder="Last name"
                    style={{
                      width: '100%',
                      cursor: 'not-allowed',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row> 
            </div> )}
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
