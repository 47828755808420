import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../services';
import { checkPermission } from '../utills/checkPermission';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import theme from '../theme';
import { Row, Col, Button, Input, Space, Tooltip, Form, Checkbox, Select } from 'antd';
import { Card, CardTable, Icon } from '../components';
import {
  faPlus,
  faEdit,
  faTrash,
  faEye,
  faTimes,
  faSave,
  faSortDown,
  faCheck,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectIcon } from "../components/icon";
import styled from "styled-components";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { ComparePassword } from './ComparePassword';
import Cookies from 'universal-cookie';
import Modal from "react-awesome-modal";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import editorText from "../constant/editorText";
const swal = withReactContent(Swal);
const cookies = new Cookies();
const { Option } = Select;
const EditorWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.borderColor};
`;
export default function ReceptionManagement() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const { receptionID } = useParams();
  const [receptionForm] = Form.useForm();
  const [isConditon, setisConditon] = useState(false);
  const [viewConsentModal, setViewConsentModal] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState("");
  const [isAddReceptionAdmin, setisAddReceptionAdmin] = useState(false);
  const [buildings, setBuildings] = useState();
  const fetchUser = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/reception_admin?building_id=all`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };

    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          formatData(sortBy(get(res, 'data.data'), ['created_at']).reverse())
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };
  const formatData = (data) => {
    const dataFormat = data.map((user) => {
      return {
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        user_type: user.user_type,
        email: user.email ? user.email : "-",
        phone: user.phone.slice(0, 6) + 'XXXX',
        building_name: user.building_name
      };
    });
    setData(dataFormat);
  };
  useEffect(() => {
    fetchUser();
    CheckConsent();
    getBuilding();
  }, []);

  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setState({ searchText: '' });
  };
  const getBuilding = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/buildings`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          console.log(get(res, "data.data"));
          setBuildings(get(res, "data.data"));
          receptionForm.setFieldsValue({
            building: res.data.data[0].id,
          });
        }
      })
  };
  const handleChangeBuilding = (value) => {
    const selectedBuilding = find(buildings, ["id", value]);
  };
  const openConsentModal = (consent) => {
    setSelectedConsent(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(consent)
      )
    ))
  };
  const handleCondition = (e) => {
    setisConditon(e.target.checked)
  }
  const handleConsentSave = () => {
    setisAddReceptionAdmin(true);
    setViewConsentModal(false);
    setisConditon(false);

  };
  const handleCancel = () => {
    setisAddReceptionAdmin(false);
    setViewConsentModal(false);
  };
  const handleSave = () => {
    setisAddReceptionAdmin(false);
    receptionForm
      .validateFields()
      .then((values) => {
        submitReceptionForm(values);
      })
  };
  const CheckConsent = () => {
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/consents/latest`,
    }
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          openConsentModal(res.data.data.content);
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        swal.fire({
          icon: "error",
          title: 'Created failed',
          text: get(err.response.data, 'message'),
          showConfirmButton: true,
          confirmButtonColor: "#2F79FF",
          confirmButtonText: "Close",
        });
      });
  }
  const createReceptionSuccess = (values) => {
    swal.fire({
      icon: "success",
      title: "Reception added",
      text: `Reception "${values.firstname}" is successfully added`,
      showConfirmButton: true,
      confirmButtonColor: "#2F79FF",
      confirmButtonText: "Close",
    });
    fetchUser();
  };
  const submitReceptionForm = (values) => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const o = {
      method: "get",
      url: `${process.env.REACT_APP_API}/consents/latest`,
    }
    api(o)
      .then((res) => {
        if(res.data.code === 200){
          const value = {
            building_id: Number(values.building),
            personal_id: values.citizenID,
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            phone: values.phone,
            password: values.password,
            user_type:3,
            consent_id: res.data.data.id,
          };
          const option = {
            method: "post",  
            url: `${process.env.REACT_APP_API}/users`,
            headers: {
              'Authorization': 'Bearer ' + cookies.get('accessToken'),
              'Content-Type': 'application/json'
            },
            data: value,
          };
          api(option)
          .then((res) => {
            global.store.dispatch({
              type: "set_loading_show",
              data: { show: false },
            });
    
            if (res.data.code === 201) {
              createReceptionSuccess(values, res);
            }
          })
          .catch((err) => {
            global.store.dispatch({
              type: "set_loading_show",
              data: { show: false },
            });
            swal.fire({
              icon: "error",
              title: 'Created failed',
              text: get(err.response.data, 'message'),
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
          });
      }
    })
  };
  const columns = [
    {
      title: 'Firstname',
      dataIndex: 'firstname',
      key: 'firstname',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      ...getColumnSearchProps('firstname'),
    },
    {
      title: 'Lastname',
      dataIndex: 'lastname',
      key: 'lastname',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      ...getColumnSearchProps('lastname'),
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Telephone',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Building',
      dataIndex: 'building_name',
      key: 'building_name',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      responsive: ['sm'],
    },

    {
      title: 'Action',
      key: 'reception_id',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      render: (organize) => (

        <Space size="middle">
          <Tooltip title="Edit Reception" color={theme.primaryColor}>
            <Button
              icon={<Icon icon={faEdit} />}
              type="text"
              onClick={() => {
                history.push(
                  `/management/reception/editreception/${organize.id}`
                );
              }}
            />
          </Tooltip>
          {checkPermission('2') && (
            <Tooltip title="Delete Reception" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faTrash} />}
                type="text"
                onClick={() =>
                  ComparePassword('user', organize, fetchUser, '')
                }
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col xs={12}>
                <div style={{ marginTop: '10px' }}>
                  <p className="theme-2-text-1">{'All Reception'}</p>
                  <p className="theme-2-text-2">
                    {get(data, 'length') ? get(data, 'length') : 0}
                    {'\xa0Total Reception'}
                  </p>
                </div>
              </Col>
              {checkPermission('2') && (
                <Col
                  xs={0}
                  sm={10}
                  style={{ textAlign: 'right', marginTop: '2%' }}
                >
                  <Button
                    type="primary"
                    className="theme-2-button-1"
                    icon={
                      <Icon margin="0 10px 0 0" color="white" icon={faPlus} />
                    }
                    onClick={() =>
                      setViewConsentModal(true)
                    }
                  >
                    Create Reception
                  </Button>
                </Col>
              )}
              {checkPermission('2') && (
                <Col
                  xs={2}
                  sm={0}
                  style={{ textAlign: 'right', marginTop: '8%' }}
                >
                  <Tooltip
                    title="Create Reception"
                    color={theme.primaryColor}
                  >
                    <Button
                      icon={<Icon color="white" icon={faPlus} />}
                      type="primary"
                      shape="circle"
                      onClick={() =>
                        history.push(
                          '/management/reception/createreception'
                        )
                      }
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
            <Row justify="center">
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
              <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
                <CardTable
                  rowKey={(obj) => obj.organize_id}
                  columns={columns}
                  dataSource={data}
                  bordered={false}
                  pagination={{ position: ['bottomCenter'] }}
                  size="middle"
                />
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        visible={viewConsentModal}
        width="60%"
        height="70%"
        effect="fadeInDown"
      >
        <Row
          align="middle"
          style={{ height: "100%", padding: "20px" }}
        >
          <Col span={24} style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Icon
              icon={faTimes}
              color="grey"
              onClick={() => setViewConsentModal(false)}
            />
          </Col>

          <Col span={24} style={{ width: '100%', top: "0px", left: "0px", position: "absolute", backgroundColor: "#007F7B" }}>
            <h1 style={{ paddingTop: '10px', paddingLeft: "20px", color: '#fff' }}>Add Reception Admin</h1>
          </Col>
          <div style={{ width: "100%", height: "80%" }}>
            <Col span={24} style={{ height: "100%", marginTop: '20px', overflow: "auto" }}>
              <h4>Consent policy: </h4>
              <EditorWrapper>
                <Editor
                  readOnly
                  toolbarHidden
                  editorStyle={{ margin: "20px" }}
                  editorState={selectedConsent}
                  localization={{
                    translations: editorText,
                  }}
                />
              </EditorWrapper>
              <Checkbox onClick={handleCondition} checked={isConditon}>Accept</Checkbox>
            </Col>

          </div>
          <Col span={24} style={{ textAlign: 'right', margin: '5px' }}>
            <Button
              style={{ marginRight: '5px' }}
              key="back"
              className="theme-2-button-2"
              onClick={() => setViewConsentModal(false)}
            >
              Close
            </Button>
            {isConditon && (
              <Button
                style={{ marginLeft: '5px' }}
                key="submit"
                className="theme-button-save"
                type="primary"
                onClick={handleConsentSave}
              >
                Next
              </Button>)}
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={isAddReceptionAdmin}
        width="40%"
        height="75%"
        effect="fadeInDown"
      >
        <Row
          align="middle"
          style={{ marginTop: "60px", padding: "20px" ,height:"100%"}}
        >
          <Col span={24} style={{ position: "absolute", top: "10px", right: "10px" }}>
            <Icon
              icon={faTimes}
              color="grey"
              onClick={() => setViewConsentModal(false)}
            />
          </Col>

          <Col span={24} style={{ width: '100%', top: "0px", left: "0px", position: "absolute", backgroundColor: "#007F7B" }}>
            <h1 style={{ paddingTop: '10px', paddingLeft: "20px", color: '#fff' }}>Add Reception Admin</h1>
          </Col>
          <div style={{ width: "100%", height: "100%" }}>
          <Row>
            <Col span={24} style={{ width: "100%" }}>
              <Form
                name="receptionForm"
                form={receptionForm}
                labelAlign="left"
                // onFinish={submitForm}
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 7 },
                  md: { span: 7 },
                  lg: { span: 7 },
                  xl: { span: 7 },
                  xxl: { span: 5 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 17 },
                  md: { span: 18 },
                  lg: { span: 20 },
                  xl: { span: 20 },
                  xxl: { span: 18 },
                }}
              >
                <Row justify="center">
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="building"
                          label="Building"
                          rules={[
                            { required: true, message: "Please input building" },
                          ]}
                        >
                          <Select
                            style={{ width: "100%" }}
                            suffixIcon={<SelectIcon />}
                            menuItemSelectedIcon={<Icon icon={faCheck} />}
                            placeholder="Building"
                            onChange={(value) => handleChangeBuilding(value)}
                          >
                            {buildings &&
                              buildings.map((building) => {
                                return (
                                  <Option
                                    key={building.id} value={building.id}
                                  >
                                    {building.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="E-Mail"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input E-mail!",
                            },
                            {
                              min: 1,
                              message: "minimum length = 1",
                            },
                            {
                              max: 165,
                              message: "maximum length = 165",
                            },
                          ]}
                        >
                          <Input
                            placeholder="E-Mail"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="First Name"
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: "Please input first Name!",
                            },
                            {
                              min: 1,
                              message: "minimum length = 1",
                            },
                            {
                              max: 165,
                              message: "maximum length = 165",
                            },
                          ]}
                        >
                          <Input
                            placeholder="First Name"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Last Name"
                          name="lastname"
                          rules={[
                            {
                              required: true,
                              message: "Please input Last Name!",
                            },
                            {
                              min: 1,
                              message: "minimum length = 1",
                            },
                            {
                              max: 165,
                              message: "maximum length = 165",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Last Name"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Telephone"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please input Telephone number!",
                            },
                            {
                              min: 1,
                              message: "minimum length = 1",
                            },
                            {
                              pattern: "^[+]?[0-9]{9,12}$",
                              message: "Your telephone number is incorrect format.",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Telephone"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Citizen ID"
                          name="citizenID"
                          rules={[
                            {
                              required: true,
                              message: "Please input Citizen ID!",
                            },
                            {
                              min: 1,
                              message: "minimum length = 1",
                            },
                            {
                              max: 165,
                              message: "maximum length = 165",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Citizen ID"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input Password!",
                            },
                            {
                              min: 1,
                              message: "minimum length = 1",
                            },
                            {
                              max: 165,
                              message: "maximum length = 165",
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder="Password"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Confirm Password"
                          name="confirmPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please input Confirm Password!",
                            },
                            {
                              min: 1,
                              message: "minimum length = 1",
                            },
                            {
                              max: 165,
                              message: "maximum length = 165",
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (!value || getFieldValue("password") === value) {
                                  return Promise.resolve();
                                }
              
                                return Promise.reject(
                                  "The two passwords that you entered do not match!"
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            placeholder="Confirm Password"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <div style={{ textAlign: 'right', margin: '5px' }}>
                <Button
                  style={{ marginRight: '5px' }}
                  key="back"
                  className="theme-2-button-2"
                  onClick={handleCancel}
                >
                  Close
                </Button>
                <Button
                  style={{ marginLeft: '5px' }}
                  key="submit"
                  className="theme-button-save"
                  type="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </Col>
            </Row>
          </div>
        </Row>
      </Modal>
    </div>
  );
}