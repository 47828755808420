import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../services';
import { checkPermission } from '../utills/checkPermission';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Row, Col, Space, Button} from 'antd';
import { Icon, Card, UserCard } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faEdit,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { ComparePassword } from './ComparePassword';
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);

export default function ViewOrganization() {
  const history = useHistory();
  const { organizeID } = useParams();
  const [organizeInfo, setOrganizeInfo] = useState();
  const [organizeAdmin, setOrganizeAdmin] = useState([]);
  const [stampRate, setStampRate] = useState([]);
  const cookies = new Cookies();

  const fetchOrganization = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/organizations/${organizeID}`,
      headers:{
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setOrganizeInfo(get(res, 'data.data'));
        } else {
          swal.fire({
            icon: 'error',
            title: get(res, 'data.code'),
            text: get(res, 'data.message'),
            showConfirmButton: true,
            confirmButtonColor: '#2F79FF',
            confirmButtonText: 'Close',
          });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  const getOrgAdmin = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const o = {
      method: "get",
      url: `${process.env.REACT_APP_API}/users/org_admin?org_id=${organizeID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    }
    api(o)
      .then((res) => {
        if(res.data.code === 200)
        {
          setOrganizeAdmin(get(res, 'data.data'));
        }
        else {
          swal.fire({
            icon: 'error',
            title: get(res, 'data.code'),
            text: get(res, 'data.message'),
            showConfirmButton: true,
            confirmButtonColor: '#2F79FF',
            confirmButtonText: 'Close',
          });
        }
      })
  }

  const getStampRate = () => {
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/organizations/${organizeID}/stamp_template`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          setStampRate(get(res, 'data.data'));
        } else {
          swal.fire({
            icon: 'error',
            title: get(res, 'data.code'),
            text: get(res, 'data.message'),
            showConfirmButton: true,
            confirmButtonColor: '#2F79FF',
            confirmButtonText: 'Close',
          });
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  }

  useEffect(() => {
    fetchOrganization();
    getStampRate();
    getOrgAdmin();
  }, []);

  const onDeleteOrganizeSuccess = () => {
    swal.fire({
      icon: 'success',
      title: 'Success',
      showConfirmButton: true,
      confirmButtonColor: '#2F79FF',
      confirmButtonText: 'Close',
    });
    history.goBack();
  };

  const OrganizeSection = () => {
    return (
      <Card margin="20px 0">
        <Row gutter={[0, 0]}>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            {organizeInfo && (
              <>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'Organize Name : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(organizeInfo, 'name')}
                    </p>
                  </Space>
                </div>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'Buiiding : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(organizeInfo, 'building_name')}
                    </p>
                  </Space>
                </div>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'Tax ID : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(organizeInfo, 'tax_id')}
                    </p>
                  </Space>
                </div>
                <div>
                </div>
                <div>
                  <p className="theme-2-text-3">
                    {'Organization administrator : \xa0'}
                    {(organizeAdmin.length === 0) && (
                      <span
                        style={{
                          fontSize: '12px',
                          paddingLeft: '10px',
                          fontWeight: 'normal',
                        }}
                      >
                        No organization administrator
                      </span>
                    )}
                  </p>
                  {organizeAdmin && (
                    <OrganizeAdminList /> 
                  )}
                </div>
                {(stampRate.length !== 0) && (
                <div>
                  <Row>
                    <p className="theme-2-text-3">{'Stamp Code : \xa0'}</p>
                    <p className="theme-2-text-4">
                    {stampRate.map((item) => {
                      return (
                        <p style={{ marginLeft: '10px' }}>
                          ●  {item.stamp_code} : {item.template_name}
                        </p>
                      )})}
                    </p>
                  </Row>
                </div>)}
              </>
            )}
            {checkPermission('2') && (
              <Row justify="center" gutter={[0, 8]}>
                <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Button
                    className="theme-2-button-3"
                    block
                    icon={<Icon margin="0 10px 0 0" icon={faEdit} />}
                    onClick={() => {
                      history.push(
                        `/management/organization/editorganization/${organizeID}`
                      );
                    }}
                  >
                    Edit Organization
                  </Button>
                </Col>
                {checkPermission('2') && (
             
                <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Button
                    className="theme-2-button-4"
                    type="primary"
                    onClick={() =>
                      ComparePassword(
                        'organization',
                        organizeInfo,
                        onDeleteOrganizeSuccess,
                        ''
                      )
                    }
                    block
                    icon={
                      <Icon color="white" margin="0 10px 0 0" icon={faTrash} />
                    }
                  >
                    Delete Organization
                  </Button>
                </Col>
             
            )}
              </Row>
            )}
            
          </Col>
        </Row>
      </Card>
    );
  };

  const OrganizeAdminList = () => {
    return (
      <div style={{ marginBottom: '10px' }}>
        <Space>
          {organizeAdmin.map((item) => {
            return (
              <UserCard
                key={get(item, 'id')}
                user={{
                  id: get(item, 'id'),
                  firstname: get(item, 'firstname'),
                  lastname: get(item, 'lastname'),
                  email: get(item, 'email'),
                  telephone_number: get(item, 'phone'),
                  face_img: get(item, 'portrait_photo'),
                }}
              />
            );
          })}
        </Space>
      </div>
    );
  };

  return (
    <div className="theme-2-content">
      <Row justify="end">
        <div style={{ marginTop: '20px' }}>
          <Button
            type="default"
            onClick={() => {
              history.goBack();
            }}
            className="theme-2-button-2"
            icon={
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: '10px' }}
              />
            }
          >
            Back
          </Button>
        </div>
      </Row>
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <OrganizeSection />
        </Col>
      </Row>
    </div>
  );
}
