import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../services';
import { checkPermission } from '../utills/checkPermission';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import get from 'lodash/get';
import theme from '../theme';
import { Row, Col, Button, Input, Space, Tooltip } from 'antd';
import { Card, CardTable, Icon } from '../components';
import {
  faPlus,
  faEdit,
  faTrash,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { ComparePassword } from './ComparePassword';
import Cookies from 'universal-cookie';
import sortBy from 'lodash/sortBy';

const swal = withReactContent(Swal);

export default function OrganizationManagement() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const cookies = new Cookies();
  const fetchUser = () => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/organizations`,
      headers:{
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.code === 200) {
          setData(sortBy(get(res, 'data.data'), ['created_at']).reverse());

        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };
  useEffect(() => {
    fetchUser();
  }, []);

  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters  , confirm)}
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters , confirm) => {
    clearFilters();
    setState({ searchText: '' });
    confirm();
  };

  const columns = [
    {
      title: 'Organization Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      className: 'theme-2-table-text',
      width: '40%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Building',
      dataIndex: 'building_name',
      key: 'building_name',
      align: 'center',
      className: 'theme-2-table-text',
      width: '30%',
      responsive: ['sm'],
    },
    
    {
      title: 'Action',
      key: 'organize_id',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      render: (organize) => (
        <Space size="middle" >
          {(checkPermission("2") ? (checkPermission("2")) : (checkPermission("4"))) && (
           
            <Tooltip title="View Organize" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faEye} />}
                type="text"
                onClick={() => {
                  history.push(
                    `/management/organization/vieworganization/${get(
                      organize,
                      'id'
                    )}`
                  );
                }}
              />
            </Tooltip>
          )}
          {checkPermission('2') && (
            <Tooltip title="Edit Organize" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faEdit} />}
                type="text"
                onClick={() => {
                  history.push(
                    `/management/organization/editorganization/${get(
                      organize,
                      'id'
                    )}`
                  );
                }}
              />
            </Tooltip>
          )}
          {checkPermission('2') && (
            <Tooltip title="Delete Organize" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faTrash} />}
                type="text"
                onClick={() =>
                  ComparePassword('organization', organize, fetchUser, '')
                }
              />
            </Tooltip>
          )}
          {!checkPermission('2') && <div>-</div>}
        </Space>
      ),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col xs={12}>
                <div style={{ marginTop: '10px' }}>
                  <p className="theme-2-text-1">{'All Organization'}</p>
                  <p className="theme-2-text-2">
                    {get(data, 'length') ? get(data, 'length') : 0}
                    {'\xa0Total Organization'}
                  </p>
                </div>
              </Col>
              {checkPermission('2') && (
                <Col
                  xs={0}
                  sm={10}
                  style={{ textAlign: 'right', marginTop: '2%' }}
                >
                  <Button
                    type="primary"
                    className="theme-2-button-1"
                    icon={
                      <Icon margin="0 10px 0 0" color="white" icon={faPlus} />
                    }
                    onClick={() =>
                      history.push(
                        '/management/organization/createorganization'
                      )
                    }
                  >
                    Create Organization
                  </Button>
                </Col>
              )}
              {checkPermission('2') && (
                <Col
                  xs={2}
                  sm={0}
                  style={{ textAlign: 'right', marginTop: '8%' }}
                >
                  <Tooltip
                    title="Create Organization"
                    color={theme.primaryColor}
                  >
                    <Button
                      icon={<Icon color="white" icon={faPlus} />}
                      type="primary"
                      shape="circle"
                      onClick={() =>
                        history.push(
                          '/management/organization/createorganization'
                        )
                      }
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
            <Row justify="center">
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
              <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
                <CardTable
                  rowKey={(obj) => obj.organize_id}
                  columns={columns}
                  dataSource={data}
                  bordered={false}
                  pagination={{ position: ['bottomCenter'] }}
                  size="middle"
                />
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
