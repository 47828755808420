import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../services';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AuthenContext from '../utills/useAuthentication';
import { QRCode } from 'react-qrcode-logo';
import {
    Row,
    Col,
    Button,
    Form,
    InputNumber,
    Card as BaseCard,
    Select,
    Space,
    TimePicker,
    DatePicker,
    Alert
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave,
    faFileExport
} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
import moment from 'moment';

const { Option, OptGroup } = Select;

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;
const swal = withReactContent(Swal);

const cookies = new Cookies();
const { RangePicker } = DatePicker;

export default function ViewReport() {
    const [getReport] = Form.useForm();
    const report = [
        { id: "1", name: "Tax Summary" },
        { id: "2", name: "Tax" },
        { id: "3", name: "Transaction Shift Summary" },
        { id: "4", name: "Transaction Shift Detail" },
        { id: "5", name: "Card Lost" },
        { id: "6", name: "Stamp Usage Invoice" },
        { id: "7", name: "Stamp Usage Voucher" },
    ]
    function myReport(option) {
        global.store.dispatch({
            type: "set_loading_show",
            data: { show: true },
        });
        api(option)
            .then((res) => {  
               setDataPDF(res.data);
            })
            .catch( async (err) =>{
                const error =  JSON.parse(await err.response.data.text()) ;
                swal.fire({
                    icon: "error",
                    title: 'Invitation failed',
                    text: error.message,
                    showConfirmButton: true,
                    confirmButtonColor: "#2F79FF",
                    confirmButtonText: "Close",
                });
            })
            .finally(() => {
                global.store.dispatch({
                    type: "set_loading_show",
                    data: { show: false },
                });
            })
    }
    const onSetTime = (time) => {
        const datetime = new Date(time);
        let temp = datetime.toString().split(" (")[0]
        return moment(temp).format("YYYY-MM-DD");
    }

    const onFinish = (fieldsValue) => {
        const values = {
            "begin_time": onSetTime(fieldsValue.datetime[0]._d),
            "end_time": onSetTime(fieldsValue.datetime[1]._d),
            "type": Number(fieldsValue.type)
        }
        const option = {
            method: 'POST',
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + cookies.get('accessToken'),
                'Content-Type': 'application/json'
            },
            url:`${process.env.REACT_APP_API}/parking/report`,
            data: JSON.stringify(values)

        };
        myReport(option)
    };
    const setDataPDF = (data) => {
        const file = new Blob(
            [data],
            { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }
    return (
        <div className="theme-2-content">
            <Form
                name="getReport"
                form={getReport}
                labelAlign="left"
                onFinish={onFinish}
                labelCol={{
                    xs: { span: 24 },
                    sm: { span: 7 },
                    md: { span: 7 },
                    lg: { span: 7 },
                    xl: { span: 7 },
                    xxl: { span: 5 },
                }}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 17 },
                    md: { span: 18 },
                    lg: { span: 20 },
                    xl: { span: 20 },
                    xxl: { span: 18 },
                }}
                initialValues={{ oneTime: false }}
            >
                <Card
                    title="Report Information"
                    extra={
                        <Row>
                            <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                                <Form.Item style={{ paddingTop: '15%' }}>
                                    <Space>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className="theme-2-button-1"
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={faFileExport}
                                                    style={{ marginRight: '10px', color: 'white' }}
                                                />
                                            }
                                        >
                                            Export report
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                                <Form.Item style={{ paddingTop: '100%' }}>
                                    <Space>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className="theme-2-button-1"
                                            icon={<FontAwesomeIcon icon={faFileExport} />}
                                        ></Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                >
                    <Row justify="center">
                        <Col xxl={12} xl={12} lg={16} md={16} sm={24} xs={24}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Select a report"
                                        name="type"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select a report!',
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select a report"
                                            style={{ width: "100%" }}
                                        >
                                            {report.map((item) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Date"
                                        name="datetime"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your date!',
                                            }
                                        ]}
                                    >
                                        <RangePicker
                                            ranges={{
                                                Today: [moment(), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            }}
                                            showTime
                                            format="YYYY-MM-DD"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Card>
            </Form>
        </div>
    );
}
