import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../services';
import { checkPermission } from '../utills/checkPermission';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import get from 'lodash/get';
import theme from '../theme';
import { Row, Col, Button, Input, Space, Tooltip } from 'antd';
import { Card, CardTable, Icon } from '../components';
import {
  faPlus,
  faTrash,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { ComparePassword } from './ComparePassword';
import Cookies from 'universal-cookie';
import sortBy from 'lodash/sortBy';


const swal = withReactContent(Swal);

export default function Cars() {
  const history = useHistory();
  const [data, setData] = useState();
  const [totalData, setTotalData] = useState();
  const [searchCol, setSearchCol] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState("");
  const cookies = new Cookies();

  const getCar = (limit, offset , search) => {
    if(search) setPage(1);
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/cars?limit=${limit}&offset=${offset}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          setSearchCol('');
          setSearchData('');
          setData(
            sortBy(get(res, 'data.data.list'), ['created_at']).reverse()
          );
          setTotalData(get(res, 'data.data'))
          formatData(sortBy(get(res, 'data.data.list'), ['created_at']).reverse() )
        }
      })
  }

  const formatData = (data) => {
    const dataFormat = data.map((car) => {
        return {
            id: car.id,
            firstname: car.user.firstname,
            lastname: car.user.lastname,
            org_id: car.user.org_id,
            org_name: car.user.org_name,
            license_plate: car.license_plate_number,
            province: car.license_plate_province,
        }
    })
    setData(dataFormat);
  }

  const searchFilter = (limit, offset , search , col , data) => {
    if(search) setPage(1);
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    let option = {}
    if(col === "firstname")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/cars?limit=${limit}&offset=${offset}&fname=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    else if(col === "lastname")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/cars?limit=${limit}&offset=${offset}&lname=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    else if(col === "license_plate")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/cars?limit=${limit}&offset=${offset}&lp=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setSearchCol(col);
          setSearchData(data);
          setData(
            sortBy(get(res, 'data.data.list'), ['created_at']).reverse()
          );
          setTotalData(get(res, 'data.data'))
          formatData(sortBy(get(res, 'data.data.list'), ['created_at']).reverse() )
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };

  const handleChange = (pagination) => {
    setPage(pagination.current);
    const offset = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    if(searchCol === "") getCar(limit,offset,false);
    else searchFilter(limit, offset , false , searchCol , searchData)
  };

  useEffect(() => {
    getCar(10,0,true);
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchCol === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchData]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, dataIndex) => {
    searchFilter(10,0,true,dataIndex,selectedKeys[0])
  };

  const handleReset = (clearFilters) => {
    getCar(10,0,true);
    clearFilters();
  };

  const columns = [
    {
      title: 'Firstname',
      dataIndex: 'firstname',
      key: 'firstname',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      ...getColumnSearchProps('firstname'),
    },
    {
      title: 'Lastname',
      dataIndex: 'lastname',
      key: 'lastname',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      ...getColumnSearchProps('lastname'),
    },
    {
      title: 'License Plate',
      dataIndex: 'license_plate',
      key: 'license_plate',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      responsive: ['sm'],
      ...getColumnSearchProps('license_plate'),
    },
    {
      title: 'Province',
      dataIndex: 'province',
      key: 'province',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
    },
    {
      title: 'Organization',
      dataIndex: 'org_name',
      key: 'org_name',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      responsive: ['sm'],
    },
    {
      title: 'Action',
      key: 'user_id',
      align: 'center',
      className: 'theme-2-table-text',
      width: '20%',
      render: (cars) => (
        <Space size="middle" >
          {(checkPermission("2") ? (checkPermission("2")) : (checkPermission("4"))) && (
           
            <Tooltip title="View Cars" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faEye} />}
                type="text"
                onClick={() => {
                  history.push(`/parking/view_cars/${get(cars,'id')}`);
                  }}
              />
            </Tooltip>
          )}
          {checkPermission('2') && (
            <Tooltip title="Delete Cars" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faTrash} />}
                type="text"
                onClick={() =>
                  ComparePassword('cars', cars, getCar, '')
                }
              />
            </Tooltip>
          )}
          {!checkPermission('2') && <div>-</div>}
        </Space>
      ),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col xs={12}>
                <div style={{ marginTop: '10px' }}>
                  <p className="theme-2-text-1">{'All Cars'}</p>
                  <p className="theme-2-text-2">
                    {get(totalData,"total") ? get(totalData,"total") : 0}
                    {'\xa0Total Cars'}
                  </p>
                </div>
              </Col>
              <Col
                  xs={0}
                  sm={10}
                  style={{ textAlign: 'right', marginTop: '2%' }}
                >
                  <Button
                    type="primary"
                    className="theme-2-button-1"
                    icon={
                      <Icon margin="0 10px 0 0" color="white" icon={faPlus} />
                    }
                    onClick={() =>
                      history.push(
                        '/parking/add_car'
                      )
                    }
                  >
                    Add Car
                  </Button>
                  
                </Col>
            </Row>
            <Row justify="center">
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
              <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
                <CardTable
                  rowKey={(obj) => obj.organize_id}
                  columns={columns}
                  dataSource={data}
                  bordered={false}
                  onChange={handleChange}
                  pagination={{
                    current: page,
                    position: ['bottomCenter'],
                    total: get(totalData,"total"),
                  }}
                  size="middle"
                />
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
