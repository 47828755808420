import React , { useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Theme from "../theme/theme_2";
import { OrganizationForm } from "../components";
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);
const cookies = new Cookies();

export default function CreateOrganization() {
  const history = useHistory();
  const [initialRate, setInitialRate] = useState();
  const createOrganizeSuccess = (values) => {
    swal.fire({
      icon: "success",
      title: "Organize added",
      text: `Organize "${values.name}" is successfully added`,
      showConfirmButton: true,
      confirmButtonColor: "#2F79FF",
      confirmButtonText: "Close",
    });
    history.push("/management/organization");
  };

  const submitForm = (fieldsValue) => {
    if (initialRate == "") {

    }else{
      const values = {
        name: get(fieldsValue, "organizeName"),
        building_id: parseInt(get(fieldsValue, "building")),
        org_admins: get(fieldsValue, "organizeAdmin"),
        tax_id:get(fieldsValue,"taxID"),
        rate_templates: initialRate
      };
      const option = {
        method: "post",
        url: `${process.env.REACT_APP_API}/organizations`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
        data: values,
      };
      global.store.dispatch({ type: "set_loading_show", data: { show: true } });
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
  
          if (res.data.code === 201) {
            createOrganizeSuccess(values, res.data);
          }
        })
        .catch((err) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          swal.fire({
            icon: "error",
            title: 'Created failed',
            text: get(err.response.data, 'message'),
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        });
    }
   
  };
  return (
    <div>
      <Theme PageName={"Create Organization"}>
        <OrganizationForm type="create" submitForm={submitForm} initialRate={(e)=>setInitialRate(e)}/>
      </Theme>
    </div>
  );
}
