import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { api } from '../services';
import { checkPermission } from '../utills/checkPermission';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Row, Col, Space, Button, Image } from 'antd';
import { Icon, Card, UserCard } from '../components';
import { faCheck, faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApprovedModal } from './ApproveModal';
import Time from 'react-time-format'
import LogoImage from "../assets/images/logo_ths.png";
const swal = withReactContent(Swal);

export default function ViewRequest() {
    const history = useHistory();
    const location = useLocation();
    const [dataNew, setDataNew] = useState();
    const ViewDetail = props => {
        useEffect(() => {
            setDataNew(location.state.detail.detail)
        }, [location]);
        const formatDate = (issued_datetime) => {
            issued_datetime = issued_datetime * 1000
            let d = new Date(issued_datetime);

            return d.getTime()
        }
        return (

            <Row gutter={[0, 0]}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card margin="20px 0">
                        <Row justify="space-between">
                            <Col span={12}>
                                <div style={{ marginTop: '10px' }}>
                                    <p className="theme-2-text-3">{'Request Detail \xa0'}</p>
                                    <p className="theme-2-text-2"></p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div style={{ marginTop: '10px', float: 'right' }}>
                                    <Row >
                                        <Col span={8} style={{ marginRight: '10px', maxWidth: '100%' }}>
                                            <Button
                                                className="theme-2-button-4"
                                                type="primary"
                                                onClick={() => {
                                                    ApprovedModal('request', location.state.detail, 'reject', "", '');
                                                }}
                                                block
                                                icon={<Icon color="white" margin="0 10px 0 0" icon={faTimes} />}
                                            >
                                                Rejected
                                            </Button>
                                        </Col>
                                        <Col span={8} style={{ marginRight: '10px', maxWidth: '100%' }}>
                                            <Button
                                                className="theme-2-button-4"
                                                type="primary"
                                                block
                                                onClick={() => {
                                                    ApprovedModal('request', location.state.detail, 'approve', "", '');
                                                }}
                                                icon={<Icon color="white" margin="0 10px 0 0" icon={faCheck} />}
                                            >
                                                Approved
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <hr color="#F5F5F5" width="100%" />
                        <>
                            <div>
                                <Space>
                                    <p className="theme-2-text-2">{'Timestamp : \xa0'}</p>
                                    <p className="theme-2-text-2">
                                        <Time value={formatDate(location.state.detail.created_at)} format="DD/MM/YYYY hh:mm:ss" />
                                    </p>
                                </Space>
                            </div>
                            <div>
                                <Space>
                                    <p className="theme-2-text-2">{'Requester : \xa0'}</p>
                                    <p className="theme-2-text-2">
                                        {location.state.detail.requester}
                                    </p>
                                </Space>
                            </div>
                            <div>
                                <Space>
                                    <p className="theme-2-text-2">{'First name : \xa0'}</p>
                                    <p className="theme-2-text-2">
                                        {location.state.detail.firstname}
                                    </p>
                                </Space>
                            </div>
                            <div>
                                <Space>
                                    <p className="theme-2-text-2">{'Last name : \xa0'}</p>
                                    <p className="theme-2-text-2">
                                        {location.state.detail.lastname}
                                    </p>
                                </Space>
                            </div>
                            <div>
                                <Space>
                                    <p className="theme-2-text-2">{'E-Mail : \xa0'}</p>
                                    <p className="theme-2-text-2">
                                        {location.state.detail.email}
                                    </p>
                                </Space>
                            </div>
                            <div>
                                <Space>
                                    <p className="theme-2-text-2">{'Phone : \xa0'}</p>
                                    <p className="theme-2-text-2">
                                        {location.state.detail.phone}
                                    </p>
                                </Space>
                            </div>
                            <div>
                                <Space>
                                    <p className="theme-2-text-2">{'Type : \xa0'}</p>
                                    <p className="theme-2-text-2">
                                        {location.state.detail.transaction_type}
                                    </p>
                                </Space>
                            </div>
                            <div>
                                <Space>
                                    <p className="theme-2-text-2">{'Detail : \xa0'}</p>
                                    <p className="theme-2-text-2"></p>
                                </Space>
                            </div>
                            <div style={{ marginLeft: '50px' }}>
                                {dataNew && (
                                    <div>
                                        <div>
                                            {dataNew.building_name && (
                                                <Space>
                                                    <p className="theme-2-text-2">{'Building name : \xa0'}</p>
                                                    <p className="theme-2-text-2">
                                                        {dataNew.building_name}
                                                    </p>
                                                </Space>)}
                                        </div><div>
                                            {dataNew.org_name && (
                                                <Space>
                                                    <p className="theme-2-text-2">{'Organize name : \xa0'}</p>
                                                    <p className="theme-2-text-2">
                                                        {dataNew.org_name}
                                                    </p>
                                                </Space>)}</div>
                                        <div> {dataNew.personal_id && (
                                            <Space>
                                                <p className="theme-2-text-2">{'Personal ID : \xa0'}</p>
                                                <p className="theme-2-text-2">
                                                    {dataNew.personal_id}
                                                </p>
                                            </Space>)}</div>
                                        <div> {dataNew.email && (
                                            <Space>
                                                <p className="theme-2-text-2">{'Email : \xa0'}</p>
                                                <p className="theme-2-text-2">
                                                    {dataNew.email}
                                                </p>
                                            </Space>)}</div>
                                        <div> {dataNew.firstname && (
                                            <Space>
                                                <p className="theme-2-text-2">{'First name : \xa0'}</p>
                                                <p className="theme-2-text-2">
                                                    {dataNew.firstname}
                                                </p>
                                            </Space>)}</div>
                                        <div> {dataNew.lastname && (
                                            <Space>
                                                <p className="theme-2-text-2">{'Last name : \xa0'}</p>
                                                <p className="theme-2-text-2">
                                                    {dataNew.lastname}
                                                </p>
                                            </Space>)}</div>
                                        <div>{dataNew.phone && (
                                            <Space>
                                                <p className="theme-2-text-2">{'Phone : \xa0'}</p>
                                                <p className="theme-2-text-2">
                                                    {dataNew.phone}
                                                </p>
                                            </Space>)}</div>
                                        <div> {dataNew.portrait_photo && (<Space>
                                            <Image className="loading_image" style={{ width: 100 }} src={dataNew.portrait_photo} />
                                        </Space>)}</div>
                                    </div>)}
                            </div>
                        </>
                    </Card>
                </Col>
            </Row>
        );
    };

    return (
        <div className="theme-2-content">
            <Row justify="end">
                <div style={{ marginTop: '20px' }}>
                    <Button
                        type="default"
                        onClick={() => history.push(`/request_approval`)}
                        className="theme-2-button-2"
                        icon={
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                style={{ marginRight: '10px' }}
                            />
                        }
                    >
                        Back
                    </Button>
                </div>
            </Row>
            <Row gutter={[0, 0]}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <ViewDetail />
                </Col>
            </Row>
        </div>
    );
}
