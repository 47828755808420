import React from 'react';
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Cookies from 'universal-cookie';


const swal = withReactContent(Swal);
const cookies = new Cookies();

export const ComparePassword = (type, dataIn, fetchFunction, floorsData) => {

  const deleteContentName = (type) => {
    if (type === "promotion") {
      return get(dataIn, "title_th");
    } else if (type === "news") {
      return get(dataIn, "title_th");
    } else if (type === "building") {
      return get(dataIn, "name");
    } else if (type === "organization") {
      return get(dataIn, "name");
    } else if (type === "floor") {
      return get(dataIn, "floor_name");
    } else if (type === "user") {
      return get(dataIn, "firstname");
    } else if (type === "cars") {
      return get(dataIn, "license_plate");
    } else {
      return type;
    }
  };

  const apiDeleteTypeUrl = (type) => {
    if (type === "building") {
      return "buildings";
    } else if (type === "organization") {
      return "organizations";
    } else if (type === "floor") {
      return "floors";
    } else if (type === "user") {
      return "users"
    } else {
      return type;
    }
  };

  const apiDeleteParamUrl = (type) => {
    if (type === "promotion") {
      return get(dataIn, "id");
    } else if (type === "news") {
      return get(dataIn, "id");
    } else if (type === "building") {
      return get(dataIn, "building_id");
    } else if (type === "organization") {
      return get(dataIn, "id");
    } else if (type === "floor") {
      return get(dataIn, "floor_id");
    } else if (type === "user") {
      return get(dataIn, "id");
    } else if (type === "cars") {
      return get(dataIn, "id");
    } else {
      return type;
    }
  };

  const checkOption = (type, throwResult) => {
    if (type === "building") {
      return {
        method: "DELETE",
        url: `${process.env.REACT_APP_API}/buildings/${dataIn.id}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
        data: {
          password: throwResult,
        },
      };
    }
    else if (type === "news" || type === "promotion") {
      return {
        method: "delete",
        url: `${process.env.REACT_APP_API}/advertisements/${dataIn.id}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
        data: {
          password: throwResult,
        },
      };
    }
    else if (type === "user") {
      return {
        method: "delete",
        url: `${process.env.REACT_APP_API}/users/${dataIn.id}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
        data: {
          password: throwResult,
        },
      };
    }
    else if (type === "organization") {
      return {
        method: "delete",
        url: `${process.env.REACT_APP_API}/organizations/${dataIn.id}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
        data: {
          password: throwResult,
        },
      };
    }
    else if (type === "cars") {
      return {
        method: "delete",
        url: `${process.env.REACT_APP_API}/cars/${dataIn.id}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
        data: {
          password: throwResult,
        },
      };
    }
  };

  swal
    .fire({
      title: "Are you sure ?",
      text:
        "you want to delete " +
        type +
        " " +
        '"' +
        deleteContentName(type) +
        '"',
      icon: "warning",
      showConfirmButton: true,
      confirmButtonColor: "#2F79FF",
      confirmButtonText: "Confirm",
      showCancelButton: true,
      cancelButtonText: <div style={{ color: "#2F79FF" }}>Cancel</div>,
      cancelButtonColor: "#e3e3e3",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        swal
          .fire({
            title: "Enter your password",
            input: "password",
            inputPlaceholder: "Enter your password",
            inputAttributes: {
              autocapitalize: "off",
              autocorrect: "off",
            },
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Confirm",
            showCancelButton: true,
            cancelButtonText: <div style={{ color: "#2F79FF" }}>Cancel</div>,
            cancelButtonColor: "#e3e3e3",
            reverseButtons: true,
            preConfirm: (password) => {
              if (!password) {
                return Swal.showValidationMessage(`Please enter password`);
              }
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              const option = checkOption(type, result.value);
              api(option)
                .then((res) => {
                  if (res.data.code === 200) {
                    swal.fire({
                      icon: "success",
                      title: "Deleted",
                      text:
                        type +
                        " " +
                        '"' +
                        deleteContentName(type) +
                        '" ' +
                        "is successfully deleted.",
                      showConfirmButton: true,
                      confirmButtonColor: "#2F79FF",
                      confirmButtonText: "Close",
                    });
                    fetchFunction();
                  } else {
                    swal.fire({
                      icon: "error",
                      title: "Failed",
                      text: "Some errors were found",
                      showConfirmButton: true,
                      confirmButtonColor: "#2F79FF",
                      confirmButtonText: "Close",
                    });
                  }
                })
                .catch((err) => {
                  global.store.dispatch({
                    type: "set_loading_show",
                    data: { show: false },
                  });
                  swal.fire({
                    icon: "error",
                    title: get(err, 'response.data.code'),
                    text: get(err, 'response.data.message'),
                    showConfirmButton: true,
                    confirmButtonColor: "#2F79FF",
                    confirmButtonText: "Close",
                  });
                });
            }
          });
      }
    });
};
