import find from "lodash/find";
import getDifferentElement from "../utills/getDifferentElement";

const initialState = {
  availableLang: [],
  defaultLang: null,
  currentLang: null,
  allLang: [],
  langList: [],
};

export default function Language(state = initialState, action) {
  switch (action.type) {
    case "set_all_lang": {
      const data = action.data;
      const defaultLang = find(data, (lang) => lang.is_default_language);
      return {
        ...state,
        allLang: data,
        defaultLang: defaultLang,
        currentLang: defaultLang.id,
        langList: [defaultLang],
        availableLang: getDifferentElement(data, [defaultLang]),
      };
    }
    case "set_current_lang": {
      return {
        ...state,
        currentLang: action.data,
      };
    }
    case "add_new_lang": {
      const data = action.data;
      const newLangList = [...state.langList, data];
      return {
        ...state,
        langList: newLangList,
        availableLang: getDifferentElement(state.allLang, newLangList),
      };
    }
    case "set_lang_list": {
      
      const data = action.data;

      return {
        ...state,
        langList: data,
        availableLang: getDifferentElement(data, state.allLang),
      };
      
    }
    default:
      return state;
  }
}
