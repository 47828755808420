import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '../services';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styled from 'styled-components';
import { Row, Col, Space, Button } from 'antd';
import { Card } from '../components';
import Modal from 'react-awesome-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';

const JuristicAdminListWrapper = styled.div`
  margin-bottom: 10px;
  overflow: scroll;
`;

const swal = withReactContent(Swal);

export default function CarForm() {
  const history = useHistory();
  const { carID } = useParams();
  const [data, setData] = useState();
  const cookies = new Cookies();

  const setRole = (data) => {
    if (data === 1) {
      return 'Project Owner';
    } else if (data === 2) {
      return 'Juristic Admin';
    } else if (data === 3) {
      return 'Reception';
    } else if (data === 4) {
      return 'Organize Admin';
    } else if (data === 5) {
      return 'Member';
    } else if (data === 6) {
      return 'Visitor';
    } else if (data === 7) {
      return 'Walk-in Visitor';
    } else if (data === 8) {
      return 'Seminar Visitor';
    }
    else return 'Nonmember'
    
  };

  const formatData = (data) => {
    const dataFormat = {
      id: data.id,
      name: data.user.firstname + '\xa0' + data.user.lastname,
      org_id: data.user.org_id,
      org_name: data.user.org_name,
      user_type: setRole(data.user.user_type), 
      license_plate: data.license_plate_number,
      province: data.license_plate_province,
    }
    setData(dataFormat);
  }

  const getCarByID = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/cars/${carID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
          formatData(get(res, "data.data"));
        }
      });
  }

  useEffect(() => {
    getCarByID();
  }, []);

  const BuildingSection = () => {
    return (
      <Card margin="20px 0">
        <Row gutter={[0, 0]}>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'Name : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(data, 'name')}
                    </p>
                  </Space>
                </div>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'License Plate : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(data, 'license_plate')}
                    </p>
                  </Space>
                </div>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'Province : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(data, 'province')}
                    </p>
                  </Space>
                </div>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'Organization : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(data, 'org_name')}
                    </p>
                  </Space>
                </div>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'User Type : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(data, 'user_type')}
                    </p>
                  </Space>
                </div>
              </>
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <div className="theme-2-content">
      <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
        <Row justify="end" style={{ paddingTop: '2%' }}>
          <Button
            type="default"
            className="theme-2-button-2"
            onClick={() => {
              history.goBack();
            }}
            icon={
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: '10px' }}
              />
            }
          >
            Back
          </Button>
        </Row>
      </Col>
      <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
        <Row justify="end" style={{ paddingTop: '5%' }}>
          <Button
            type="default"
            className="theme-2-button-2"
            onClick={() => {
              history.goBack();
            }}
            icon={
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: '10px' }}
              />
            }
          >
            Back
          </Button>
        </Row>
      </Col>
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <BuildingSection />
        </Col>
      </Row>
    </div>
  );
}
