import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { checkPermission } from '../utills/checkPermission';
import { api } from '../services';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import theme from '../theme';
import styled from 'styled-components';
import { Row, Col, Button, Card as BaseCard, Tooltip } from 'antd';
import {
  Icon,
  Tag,
  UpdateProfileForm,
  CreateAccessModal,
  DeleteModal,
  DataNotFound,
} from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';

const BuildingWrapper = styled.div`
  background: ${(props) => props.theme.bgGrey};
  padding: 10px;
  margin-bottom: 10px;
`;

const FloorWrapper = styled.div`
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.grey};
  border-radius: 5px;
  padding: 5px 8px 8px 8px;
  margin-top: 5px;
`;

const Card = styled(BaseCard)`
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0px 2px 10px rgb(0 89 83);
`;

const swal = withReactContent(Swal);
const cookies = new Cookies();
export default function ManageAccess({ userID }) {
  const [errorUser, setErrorUser] = useState(false);
  const history = useHistory();
  const [initialValue, setInitialValue] = useState();
  const [initialValueIMG, setInitialValueIMG] = useState();
  const [iCheckParent, setiCheckParent] = useState(false);
  const [initialValueParent, setInitialValueParent] = useState();
  const getUserInfo = (userID) => {
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/${userID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setErrorUser(false);
          setInitialValue(get(res, 'data.data') ? get(res, 'data.data') : []);
          getUserInfoParent(get(res, 'data.data.parent_id'))
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };
  const getUserInfoParent  = (userID) => {
    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/${userID}`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setErrorUser(false);
          setInitialValueParent(get(res, 'data.data') ? get(res, 'data.data') : []);
          setiCheckParent(true)
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        setiCheckParent(false)
      });
  };

  useEffect(() => {
    getUserInfo(userID);
  }, []);

  const onUpdateUserSuccess = (values) => {
    swal.fire({
      icon: 'success',
      title: 'Success',
      text: `User "${get(values, 'firstname') + " " + get(values , 'lastname')}" is successfully edited.`,
      showConfirmButton: true,
      confirmButtonColor: '#2F79FF',
      confirmButtonText: 'Close',
    });
  };

  const submitForm = (fieldsValue) => {
    let values = {
      firstname: fieldsValue['firstName'],
      lastname: fieldsValue['lastName'],
      phone: fieldsValue['telephone'],
      portrait_photo: '',
    };
    if(initialValueIMG) values.portrait_photo = initialValueIMG
    else values.portrait_photo = initialValue.portrait_photo
      const option = {
        method: 'put',
        url: `${process.env.REACT_APP_API}/users/${userID}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
        data: values,
      };
      global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: 'set_loading_show',
            data: { show: false },
          });
  
          if (res.data.code === 200) {
            onUpdateUserSuccess(values);
          }
        })
        .catch((err) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          swal.fire({
            icon: "error",
            title: 'Updated failed',
            text: get(err.response.data, 'message'),
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        });
  };

  return (
    <div>
      <div className="theme-2-content">
        <Row justify="end" style={{ paddingTop: '2%' }}>
          <Button
            type="default"
            className="theme-2-button-2"
            onClick={() => {
              history.push('/management/users');
            }}
            icon={
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: '10px' }}
              />
            }
          >
            Back
          </Button>
        </Row>
        <Row gutter={[8,8]}>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            {initialValue && (
              <UpdateProfileForm
                manageAccess
                userID={userID}
                submitForm={submitForm}
                initialValue={initialValue}
                initialValueParent={initialValueParent}
                setImg1={(e)=>setInitialValueIMG(e)}
                type_from={"manageUser"}
                iCheckParent={iCheckParent}
              />
            )}
            {errorUser && !initialValue && (
              <Card title="User Profile">
                <DataNotFound manageAccess />
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}
