import React from "react";
import Theme from "../theme/theme_2";
import EstempManagement from "../components/EstemForm";
export default function estempManagement() {
  return (
    <div>
      <Theme PageName={"E-Stamp Management"}>
        <EstempManagement />
      </Theme>
    </div>
  );
}
