import React, { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import AuthenContext from '../utills/useAuthentication';
import Cookies from 'universal-cookie';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Form, Input, Button, Row, Col } from 'antd';
import { FormItem } from '../components';
import { SessionTimeoutModal } from './SessionTimeoutModal';
import jwtDecode from 'jwt-decode';
const cookies = new Cookies();

const swal = withReactContent(Swal);

export default function LoginForm() {
  const history = useHistory();

  const {
    login,
    setUser,
    decodeToken,
    setTokenCookie,
    setPermissionCookie,
    getAvatar,
    setIsSessionExpired,
  } = useContext(AuthenContext);

  const onFinish = (values) => {

    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    let details = {
        "client_id":'thaisummit-client',
        "client_secret":'20bd501c-7276-4c1f-a687-b08d8779174e',
        "grant_type":'password',
        "username":values.email,
        "password":values.password,
  };

  let formBody = [];
  for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
    const option = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_KEYLOG}/token`,
      headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formBody ,
      
    };
    login(option)
      .then((response) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (response.data.access_token !='') {
          cookies.set('expiresAtTime', true);
          const decode = jwtDecode(response.data.access_token);
          const lastUrl = cookies.get('lastUrl');
          const accessToken = get(response, 'data.access_token');
          const refreshToken = get(response, 'data.refresh_token');
          const permission = get(decode, 'user_type');
          const data = decodeToken(accessToken, refreshToken,get(response,'data.expires_in'));
          setTokenCookie(accessToken, refreshToken,get(response,'data.expires_in'));
          setPermissionCookie(permission, refreshToken,get(response,'data.expires_in'));
          setIsSessionExpired(true);
          getAvatar(accessToken);

          if (permission > 4 || permission == 3) {
            swal.fire({
              icon: 'error',
              title: "User cannot access",
              text: "Please contact admin to request access",
              showConfirmButton: true,
              confirmButtonColor: '#2F79FF',
              confirmButtonText: 'Close',
            });
          }
          else if (lastUrl) {
             history.push(lastUrl);
            window.location.replace(lastUrl);
            cookies.remove('lastUrl', { path: '/' });
          } else {
            history.push('/project');
          }
        }else{
          swal.fire({
            icon: 'error',
            title: "Login failed",
            text: "Incorrect password",
            showConfirmButton: true,
            confirmButtonColor: '#2F79FF',
            confirmButtonText: 'Close',
          });
        }
      })
      .catch(function (error) {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        swal.fire({
          icon: 'error',
          title: "Login failed",
            text: "Please check your email and password",
          showConfirmButton: true,
          confirmButtonColor: '#2F79FF',
          confirmButtonText: 'Close',
        });
      });
  };

  return (
    <Row>
      { cookies.get('expiresAtTime') === 'true' && (
       <SessionTimeoutModal visible={true} /> 
      ) } 
      <Col xxl={3} xl={3} lg={3} md={1} sm={1} xs={1}></Col>
      <Col xxl={18} xl={18} lg={18} md={22} sm={22} xs={22}>
        <div className="login-form">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Row>
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <p className="theme-1-text-1">Welcome to Back Office</p>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <p className="theme-1-text-A">Welcome to Back Office</p>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                  <p className="theme-1-text-3">Email</p>
                </Col>
                <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                  <p className="theme-1-text-B">Email</p>
                </Col>
              </Col>
            </Row>
            <FormItem
              required={false}
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
                {
                  type: 'email',
                  message: 'Your email is incorrect.',
                },
                {
                  min: 8,
                  message: 'minimum length = 8',
                },
                {
                  max: 165,
                  message: 'maximum length = 165',
                },
              ]}
            >
              <Input style={{ width: '100%' }} />
            </FormItem>
            <Row>
              <Col span={12}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                  <p className="theme-1-text-3">Password</p>
                </Col>
                <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                  <p className="theme-1-text-B">Password</p>
                </Col>
              </Col>
              <Col span={12}>
                <Row justify="end">
                  <Link to="/forgotpassword">
                    <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                      <p className="theme-1-text-5">Forgot Password?</p>
                    </Col>
                    <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                      <p className="theme-1-text-D">Forgot Password?</p>
                    </Col>
                  </Link>
                </Row>
              </Col>
            </Row>
            <FormItem
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  min: 8,
                  message: 'minimum length = 8',
                },
                {
                  max: 32,
                  message: 'maximum length = 32',
                },
              ]}
            >
              <Input.Password style={{ width: '100%' }} />
            </FormItem>
            <Form.Item>
              <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="theme-1-button-1"
                >
                  Login
                </Button>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="theme-1-button-A"
                >
                  Login
                </Button>
              </Col>
            </Form.Item>
            <Col xxl={24} xl={24} lg={24} md={24} sm={0} xs={0}>
              <p className="theme-1-text-4">
                if doesn't existed account please contact admin
              </p>
            </Col>
            <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
              <p className="theme-1-text-C">
                if doesn't existed account please contact admin
              </p>
            </Col>
          </Form>
        </div>
      </Col>
      <Col xxl={3} xl={3} lg={3} md={1} sm={1} xs={1}></Col>
    </Row>
  );
}
