import React, { useState, useEffect } from "react";
import sortBy from 'lodash/sortBy';
import { api } from "../services";
import get from "lodash/get";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import theme from "../theme";
import { Tooltip, Row, Col, Button, Input, Space , Form , Select , Modal} from "antd";
import { Icon, Card, CardTable , Tag } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faCheck,
  faTimes,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { SelectIcon } from "../components/icon";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import Cookies from 'universal-cookie';

const swal = withReactContent(Swal);

export default function AddCars() {
  const [data, setData] = useState();
  const [addCarForm] = Form.useForm();
  const [organizes , setOrganize] = useState([]);
  const [provinces , setProvinces] = useState([]);
  const [totalData, setTotalData] = useState();
  const [searchCol, setSearchCol] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState("");
  const [addCar , setAddCar] = useState();
  const [isAddCar, setisAddCar] = useState(false);
  const [isMember, setisMember] = useState(false);
  
  const cookies = new Cookies();

  const getOrganization = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
        method: "get",
        url: `${process.env.REACT_APP_API}/organizations`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      }
      api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
            setOrganize(res.data.data);
        }
      })
  }
  
  const getProvince = () => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    const option = {
      method: "get",
      url: `${process.env.REACT_APP_API}/provinces`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
        if (res.data.code === 200) {
            setProvinces(get(res, "data.data"));
        }
      })
  };

  const handleChange = (pagination) => {
    setPage(pagination.current);
    const offset = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;
    if(searchCol === "") fetchUser(limit,offset,false);
    else searchFilter(limit, offset , false , searchCol , searchData)
  };

  const fetchUser = (limit, offset , search) => {
    if(search) setPage(1);
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&types=5&offset=${offset}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setSearchCol("");
          setSearchData("");
          setData(
            sortBy(get(res, 'data.data.list'), ['created_at']).reverse()
          );
          setTotalData(get(res, 'data.data'))
          formatData(sortBy(get(res, 'data.data.list'), ['created_at']).reverse() )
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };

  const formatData = (data) => {
    const dataFormat = data.map((user) => {
      return {
        id:user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email ? user.email : "-",
        org_id: user.org_id
      }
    })
    setData(dataFormat);
  }

  const searchFilter = (limit, offset , search , col , data) => {
    if(search) setPage(1);
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    let option = {}
    if(col === "email")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&types=5&email=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    else if(col === "firstname")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&types=5&fname=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    else if(col === "lastname")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&types=5&lname=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    else if(col === "organizes")
      option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/users?limit=${limit}&offset=${offset}&types=5&oid=${data}`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setSearchCol(col);
          setSearchData(data);
          setData(
            sortBy(get(res, 'data.data.list'), ['created_at']).reverse()
          );
          setTotalData(get(res, 'data.data'))
          formatData(sortBy(get(res, 'data.data.list'), ['created_at']).reverse() )
        }
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };

  useEffect(() => {
    getOrganization();
    fetchUser(10,0,true)
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, setSelectedKeys , dataIndex)}
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchCol === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchData]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, dataIndex) => {
    searchFilter(10,0,true,dataIndex.toString(),selectedKeys[0]);
  };

  const handleReset = (clearFilters , setSelectedKeys , dataIndex) => {
    if(dataIndex === "roles" || dataIndex === "orgname")
      setSelectedKeys(null)
    fetchUser(10,0,true);
    clearFilters();
  };

  const tagOrganizeAdminRender = (props) => {
    const { label, closable, onClose } = props;
    return (
      <Tag
        color={theme.bgTextButton}
        closable={closable}
        onClose={onClose}
        closeIcon={<Icon fontSize="10px" icon={faTimes} />}
      >
        {label}
      </Tag>
    );
  };

  const submitAddCarForm = (values) => {
    global.store.dispatch({ type: "set_loading_show", data: { show: true } });
    if(!isMember)
    {
      const option = {
        method: "post",
        url: `${process.env.REACT_APP_API}/cars/nonmember`,
        data: values,
      };
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });

          if (res.data.code === 201) {
            setisAddCar(false);
            addCarForm.resetFields();
            swal.fire({
              icon: "success",
              title: "Success",
              text: `Car "${get(values, "license_plate_number")}" is successfully created`,
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
          } 
        })
        .catch((err) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          swal.fire({
            icon: "error",
            title: 'Created failed',
            text: get(err.response.data, 'message'),
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        });
    }
    else
    {
      const user_id = get(addCar , "id");
      const value = {
        license_plate_number: values.license_plate_number,
        province_id: values.province_id,
        user_id: get(addCar , "id")
      }
      const option = {
        method: "post",
        url: `${process.env.REACT_APP_API}/cars`,
        data: value,
      };
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });

          if (res.data.code === 201) {
            setisAddCar(false);
            addCarForm.resetFields();
            swal.fire({
              icon: "success",
              title: "Success",
              text: `Car "${get(value, "license_plate_number")}" is successfully created`,
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
          } 
        })
        .catch((err) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
          swal.fire({
            icon: "error",
            title: 'Created failed',
            text: get(err.response.data, 'message'),
            showConfirmButton: true,
            confirmButtonColor: "#2F79FF",
            confirmButtonText: "Close",
          });
        });
    }
  };

  const AddCarModal = () => {
    const handleCancel = () => {
      addCarForm.resetFields();
      setisAddCar(false);
    };
    const handleSave = () => {
      addCarForm
        .validateFields()
        .then((values) => {
          submitAddCarForm(values);
        });
    };
    return (
      <Modal
        visible={isAddCar}
        width="60%"
        height="70%"
        title="Add Car"
        onOk={handleSave}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key="back"
            className="theme-2-button-2"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="theme-button-save"
            type="primary"
            onClick={handleSave}
          >
            Save
          </Button>,
        ]}
      >
        <Form
          name="addCarForm"
          form={addCarForm}
          labelAlign="left"
          layout="vertical"
          labelCol={{
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 },
            lg: { span: 8 },
            xl: { span: 8 },
            xxl: { span: 8 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 17 },
            md: { span: 18 },
            lg: { span: 20 },
            xl: { span: 20 },
            xxl: { span: 18 },
          }}
        >
        <Row>
            <Col span={12}>
            { isMember ? 
            <Form.Item
              label="Name"
              name="name"
            >
              <p>{get(addCar,"firstname") + " " + get(addCar,"lastname")}</p>
            </Form.Item> :
            <Form.Item
              name="firstname"
              label="Firstname"
              rules={[
                {
                  required: true,
                  message: "Please input firstname!",
                  whitespace: true,
                }
              ]}
            >
              <Input />
            </Form.Item>
            }
          </Col>
          <Col span={12}>
            { isMember ? 
              <Form.Item
                disable={isAddCar}
                label="User Type"
                name="user_type"
              >
                <p>{get(addCar,"roles")}</p>
              </Form.Item>
            :
              <Form.Item
                name="lastname"
                label="Lastname"
                rules={[
                  {
                    required: true,
                    message: "Please input lastname!",
                    whitespace: true,
                  }
                ]}
              >
                <Input />
              </Form.Item>
            }
          </Col>
        </Row>
        <Row>
        <Col span={12}>
             <Form.Item
            name="license_plate_number"
            label="License Plate"
            rules={[
              {
                required: true,
                message: "Please input license plate number!",
                whitespace: true,
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
          <Col span={12}>
            <Form.Item
              name="province_id"
              label="Province"
              rules={[
                  {
                    required: true,
                    message: "Please input province name!",
                  },
                ]}
            >
            <Select
                style={{ width: "100%" }}
                suffixIcon={<SelectIcon />}
                menuItemSelectedIcon={<Icon icon={faCheck} />}
            >
                {provinces.map((item) => {
                    return (
                        <Option key={item.id} value={item.id}>
                            {item.name}
                        </Option>
                    );
                })}  
            </Select>
          </Form.Item>
          </Col>
          { !isMember && (
          <Col span={24}>
            <Form.Item
              name="org_id"
              label="Organize Name"
              rules={[{
                required: true,
                message: "Please input organize!",
              },]}
            >
              <Select
                name="building"
                placeholder="Select organize"
                tagRender={tagOrganizeAdminRender}
                style={{ width: "100%" }}
                menuItemSelectedIcon={<Icon icon={faCheck} />}
              >
                {organizes.map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          )}
          
          
        </Row>
        </Form>
      </Modal>
    );
  };

  const columns = [
    {
      title: "Firstname",
      dataIndex: ["firstname"],
      key: "firstname",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      ...getColumnSearchProps(["firstname"]),
    },
    {
      title: "Lastname",
      dataIndex: ["lastname"],
      key: "lastname",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      ...getColumnSearchProps(["lastname"]),
    },
    {
      title: "E-Mail",
      dataIndex: ["email"],
      key: "email",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      ...getColumnSearchProps(["email"]),
    },
    {
      title: "Action",
      key: "id",
      align: "center",
      className: "theme-2-table-text",
      width: "20%",
      render: (users) => (
        <Space size="middle">
          <Tooltip title="Add Car" color={theme.primaryColor}>
            <Button
              icon={<Icon icon={faCar} />}
              type="text"
              onClick={() => {
                getProvince();
                setisMember(true);
                setAddCar(
                    {firstname :get(users,"firstname") , 
                     lastname :get(users,"lastname") ,
                     roles : "Member",
                     id : get(users,"id")})
                setisAddCar(true);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col span={6}>
                <p className="theme-2-text-1">{"All Users \xa0"}</p>
                <p className="theme-2-text-2">
                  {get(totalData,"total") ? get(totalData,"total") : 0}
                  {"\xa0Total users"}
                </p>
              </Col>
                <Col span={18}>
                <Col xs={6} sm={16} md={12} lg={6} xl={6} style={{ width: '100%', float: 'right', padding: '10px' }}>
                    <Select
                      style={{ width: "200px" }}
                      suffixIcon={<SelectIcon />}
                      menuItemSelectedIcon={<Icon icon={faCheck} />}
                      placeholder="search organization"
                      onChange={(value) => searchFilter(10,0,true,"organizes",value)}
                    >
                        {organizes.map((item) => {
                            return (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                             );
                        })}  
                    </Select>
                  </Col>
                  <Col xs={6} sm={16} md={12} lg={6} xl={6} style={{ width: '100%', float: 'right', padding: '10px' , marginRight: '150px'}}>
                    <Form.Item>
                      <Button
                        style={{ width: "350px" }}
                        type="primary"
                        className="theme-2-button-1"
                        icon={
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ marginRight: "10px" }}
                          />
                        }
                        onClick={() => {
                          getProvince();
                          setisMember(false);
                          setisAddCar(true);
                        }}
                      >
                        Add Car With Nonmember
                      </Button>
                    </Form.Item>
                  </Col>
                </Col>
            </Row>
            <Row justify="center">
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
              <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
                <CardTable
                  rowKey={(obj) => obj.id}
                  columns={columns}
                  dataSource={data}
                  bordered={false}
                  onChange={handleChange}
                  
                  pagination={{
                    current: page,
                    position: ['bottomCenter'],
                    total: get(totalData,"total"),
                  }}
                  size="middle"
                />
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={0} xs={0}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <AddCarModal/>
    </div>
  );
}