import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../services';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Row, Col, Button, Input, Space, DatePicker ,Form } from 'antd';
import { Card, CardTable, Icon } from '../components';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import Cookies from 'universal-cookie';
import styled from "styled-components";

const swal = withReactContent(Swal);
const cookies = new Cookies();
const ErrorSection = styled.div`
  color: ${({ theme }) => theme.red};
`;

export default function WebStamp() {
  const history = useHistory();
  const [searchForm] = Form.useForm();
  const [dataNew, setDataNew] = useState();
  const [total, setTotal] = useState();
  const [startD , setStartD] = useState("");
  const [endD , setEndDate] = useState("now");
  const [searchArray, setSearchArray] = useState({});
  const [searchFields, setSearchFields] = useState([]);

  function loadData(page,size){
    const addPageData = {
      ...searchArray,
      access_start_time: startD,
      access_end_time: endD,
      page: page,
      size: size
    }
    return addPageData
  }
  const getWebStampLog = (page , size) => {
    global.store.dispatch({ type: 'set_loading_show', data: { show: true } });
    const addPageData = loadData(page,size)
    const value = {
      type: "parking_webstamp",
      payload: addPageData,
    }
    const option = {
      method: "post",
      url: `${process.env.REACT_APP_API}/logging/events`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
      data: value,
    }
    api(option)
    .then((res) => {
      global.store.dispatch({
        type: "set_loading_show",
        data: { show: false },
      });
      if (res.data.code === 200) {
        setTotal(res.data.data.total)
        formatData(res.data.data.result)
      }
    })
  }

  const formatData = (data) => {
    const dataFormat = data.map((log) => {
      return {
        stamp_time: log.access_time,
        access_id: log.transaction_number,
        license_plate: log.license_plate_number,
        card_number: log.card_number,
        stamp_code: log.stamp_code,
        actor: log.actor,
        remark: log.remark,
      };
    });
    setDataNew(dataFormat);
  };

  const [error, setErrorTitle] = useState("");
  const [check, setCheck] = useState(false);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => 
            checkValue(e,dataIndex,setSelectedKeys)
          }
          onPressEnter={() => handleSearch(dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        /><ErrorSection>{error}</ErrorSection>
        <Space>
          <Button
            hidden={!check}
            type="primary"
            onClick={() => handleSearch(dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters,setSelectedKeys,dataIndex)}
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
  });

  const checkValue = (e ,dataIndex , setSelectedKeys) => {
    if(e.target.value.length >= 3) 
    {
      setCheck(true)
      setErrorTitle("")
      let name = ""
      if(dataIndex === "access_id") name = 'transaction_number'
      else if(dataIndex === "license_plate") name = 'license_plate_number'
      else if(dataIndex === "card_number") name = 'card_number'
      else if(dataIndex === "stamp_code") name = 'stamp_code'
      else if(dataIndex === "actor") name = 'actor'
      else if(dataIndex === "remark") name = 'remark'
      setSearchArray(prevState => ({
        ...prevState,
        [name]: e.target.value
      }));
    }
    else {
      setCheck(false)
      setErrorTitle("minimum length = 3")
    }
    setSelectedKeys(e.target.value ? [e.target.value] : [])
  }

  const handleSearch = (dataIndex) => {
    let name = ""
    if(dataIndex === "access_id") name = 'transaction_number'
    else if(dataIndex === "license_plate") name = 'license_plate_number'
    else if(dataIndex === "card_number") name = 'card_number'
    else if(dataIndex === "stamp_code") name = 'stamp_code'
    else if(dataIndex === "actor") name = 'actor'
    else if(dataIndex === "remark") name = 'remark'
    getWebStampLog(1,10);
    setSearchFields([...searchFields, name])
  };

  const handleReset = (clearFilters,setSelectedKeys,dataIndex) => {
    let name = ""
    if(dataIndex === "access_id") name = 'transaction_number'
    else if(dataIndex === "license_plate") name = 'license_plate_number'
    else if(dataIndex === "card_number") name = 'card_number'
    else if(dataIndex === "stamp_code") name = 'stamp_code'
    else if(dataIndex === "actor") name = 'actor'
    else if(dataIndex === "remark") name = 'remark'
    if (searchFields.length > 0) {
      setSearchFields(searchFields => searchFields.filter(data => data !== name) )
    }
    setSearchArray(prevState => {
      const coppyState = {...prevState} 
      delete coppyState[name]
      return coppyState
    });
    setSelectedKeys("")
    setErrorTitle("")
    clearFilters();
  };

  const convertDate = (handleConvertDate) => {
    let convert = handleConvertDate.split('+')[0];
    let Date = convert.split('T')[0];
    let Time = convert.split('T')[1];

    return Date+" "+Time
  };
  
  const handleSearchDate = () => {
    searchForm.validateFields().then((values) => {
      let convertstartDate, convertendDate, startDate, endDate;
      if (values.startDate !== undefined && values.startDate !== null) {
        convertstartDate = moment(
          new Date(values.startDate._d),
          "DD-MM-YYYY hh:mm:ss"
        ).format();
        startDate = convertDate(convertstartDate);
      } else startDate = "";
      if (values.endDate !== undefined && values.endDate !== null) {
        convertendDate = moment(
          new Date(values.endDate._d),
          "DD-MM-YYYY hh:mm:ss"
        ).format();
        endDate = convertDate(convertendDate);
      } else {
        endDate = "now";
      }

      const addPageSearchDate = {
        ...searchArray,
        access_start_time: startDate,
        access_end_time: endDate,
        page: 1,
        size: 10,
      };
      global.store.dispatch({ type: "set_loading_show", data: { show: true } });
      const value = {
        type: "parking_webstamp",
        payload: addPageSearchDate,
      };
      const option = {
        method: "post",
        url: `${process.env.REACT_APP_API}/logging/events`,
        headers: {
          Authorization: "Bearer " + cookies.get("accessToken"),
          "Content-Type": "application/json",
        },
        data: value,
      };
      api(option)
        .then((res) => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });

          if (res.data.code === 200) {
            setTotal(res.data.data.total);
            formatData(res.data.data.result);
            setStartD(startDate);
            setEndDate(endDate);
          } else {
            swal.fire({
              icon: "error",
              title: get(res, "data.status"),
              text: get(res, "data.payload"),
              showConfirmButton: true,
              confirmButtonColor: "#2F79FF",
              confirmButtonText: "Close",
            });
          }
        })
        .finally(() => {
          global.store.dispatch({
            type: "set_loading_show",
            data: { show: false },
          });
        });
    });
  };
  
  useEffect(() => {
    getWebStampLog(1,10);
  }, [searchFields]);

  const columns = [
    {
      title: 'Stamp Time',
      dataIndex: 'stamp_time',
      key: 'stamp_time',
      align: 'start',
      className: 'theme-2-table-text',
      render: (time) => {
        return (
          <Space>
            {moment.utc(time).local().format('YYYY-MM-DD HH:mm:ss')}
          </Space>
        );
      },
    },
    {
      title: 'Access ID',
      dataIndex: 'access_id',
      key: 'access_id',
      align: 'start',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      ...getColumnSearchProps('access_id'),
    },
    {
      title: 'License Plate',
      dataIndex: 'license_plate',
      key: 'license_plate',
      align: 'start',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      ...getColumnSearchProps('license_plate'),
    },
    {
      title: 'Card Number',
      dataIndex: 'card_number',
      key: 'card_number',
      align: 'start',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      ...getColumnSearchProps('card_number'),
    },
    {
      title: 'Stamp Code',
      dataIndex: 'stamp_code',
      key: 'stamp_code',
      align: 'start',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      ...getColumnSearchProps('stamp_code'),
    },
    {
      title: 'Actor',
      dataIndex: 'actor',
      key: 'actor',
      align: 'start',
      className: 'theme-2-table-text',
      responsive: ['sm'],
      ...getColumnSearchProps('actor'),
    },
    {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        align: 'start',
        className: 'theme-2-table-text',
        responsive: ['sm'],
      },
  ];

  return (
    <div className="theme-2-content">
      <Row gutter={[0, 0]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card margin="20px 0">
            <Row justify="space-between">
              <Col span={6}>
                <div style={{ marginTop: '10px' }}>
                  <p className="theme-2-text-1">{'Web Stamp Log \xa0'}</p>
                  <p className="theme-2-text-2">
                    {total ? total : 0}
                    {'\xa0Total web stamps'}
                  </p>
                </div>
              </Col>
              <Col span={18}>
              <Form
                  name="searchForm"
                  form={searchForm}
                >
                  <Col xs={6} sm={16} md={12} lg={6} xl={6} style={{ width: '100%', float: 'right', padding: '10px' }}>
                    <Form.Item>
                      <Button
                        className="theme-2-button-4"
                        type="primary"
                        block
                        icon={
                          <Icon color="white" margin="0 10px 0 0" icon={faSearch} />
                        }
                        onClick={handleSearchDate}
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={6} sm={16} md={12} lg={8} xl={8} style={{ width: '100%', float: 'right', padding: '10px' }}>
                    <Form.Item
                      name="endDate"
                      label="End Date"
                    >
                      <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        style={{ width: '100%', textAlign: 'right' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={6} sm={16} md={12} lg={8} xl={8} style={{ width: '100%', float: 'right', padding: '10px' }}>
                    <Form.Item
                      name="startDate"
                      label="Start Date"
                    >
                      <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        style={{ width: '100%', textAlign: 'right' }}
                      />
                    </Form.Item>
                  </Col>
                </Form>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <CardTable
                  rowKey="id"
                  columns={columns}
                  dataSource={dataNew}
                  bordered={false}
                  onChange={(e) =>
                    getWebStampLog(e.current,e.pageSize)
                  }
                  pagination={{ 
                    position: ['bottomCenter'],
                    total: total >= 10000 ? 10000 : total
                  }}
                  size="middle"
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}