import embed from "embed-video";
import uploadImageInEditor from "../utills/uploadImageInEditor";

export default {
  embedded: {
    embedCallback: (link) => {
      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
      return (detectedSrc && detectedSrc[1]) || link;
    },
  },
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "embedded",
    "emoji",
    "image",
    "remove",
    "history",
  ],
  inline: {
    options: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "monospace",
      "superscript",
      "subscript",
    ],
  },
  fontFamily: {
    options: [
      "Poppins",
      "Arial",
      "Georgia",
      "Impact",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
};
