import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../services';
import { checkPermission } from '../utills/checkPermission';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styled from 'styled-components';
import theme from '../theme';
import jwtDecode from 'jwt-decode';
import {
  Row,
  Col,
  Carousel as BaseCarousel,
  Space,
  Button,
  Tooltip,
} from 'antd';
import { Icon, Image, Card, CardTable, UserCard } from '../components';
import {
  faEdit,
  faTrash,
  faPlus,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { ComparePassword } from './ComparePassword';
import AuthenContext from '../utills/useAuthentication';
import Cookies from 'universal-cookie';
import CoverImg from "../assets/images/Cover_1.jpg";

const Carousel = styled(BaseCarousel)`
  border-radius: 12px;
  overflow: hidden;
`;
const cookies = new Cookies();
const Slideshow = () => {
  return (
    <Carousel autoplay>
      <Image
        preview={false}
        src={
          CoverImg
        }
      />
      <Image
        preview={false}
        src={
          CoverImg
        }
      />
    </Carousel>
  );
};

const swal = withReactContent(Swal);

export default function Project() {
  const history = useHistory();
  const [projectInfo, setProjectInfo] = useState();
  const [ownerAdminInfo, setOwnerAdmin] = useState();
  const [buiding, setBuilding] = useState();
  const {
    logout
  } = useContext(AuthenContext);
  const columns = [
    {
      title: 'Building name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      className: 'theme-2-table-text',
    },
    {
      title: 'Telephone',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      className: 'theme-2-table-text',
      responsive: ['sm'],
    },
    {
      title: 'Action',
      key: 'building_id',
      align: 'center',
      className: 'theme-2-table-text',
      render: (building) => (
        <Space size="small">
            <Tooltip title="View Building" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faEye} />}
                type="text"
                onClick={() => {
                  history.push(`/building/${building.id}`);
                }}
              />
            </Tooltip>
          {checkPermission('1') && (
            <Tooltip title="Edit Building" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faEdit} />}
                type="text"
                onClick={() => {
                  history.push(`/editbuilding/${building.id}`);
                }}
              />
            </Tooltip>
          )}
          {checkPermission('1') && (
            <Tooltip title="Delete Building" color={theme.primaryColor}>
              <Button
                icon={<Icon icon={faTrash} />}
                type="text"
                onClick={() => {
                  ComparePassword('building', building, getBuilding, '');
                }}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];
  const getOwneradmin = () => {

    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/users/owner_admin`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
        if (res.data.message === 'success') {
          setOwnerAdmin(get(res, 'data.data[0]'));
        } 
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };
  const getProject = () => {

    const option = {
      method: 'get',
      url: `${process.env.REACT_APP_API}/project`,
      headers: {
        'Authorization': 'Bearer ' + cookies.get('accessToken'),
        'Content-Type': 'application/json'
      },
    };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.message === 'success') {
          setProjectInfo(get(res, 'data.data'));
        } 
      })
      .catch((err) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });
      });
  };

  const getBuilding = () => {
    let buiding_id = jwtDecode(cookies.get('accessToken')) ;
    const option = {
        method: 'get',
        url: `${process.env.REACT_APP_API}/buildings/`,
        headers: {
          'Authorization': 'Bearer ' + cookies.get('accessToken'),
          'Content-Type': 'application/json'
        },
      };
    api(option)
      .then((res) => {
        global.store.dispatch({
          type: 'set_loading_show',
          data: { show: false },
        });

        if (res.data.code === 200) {
          setBuilding(
            sortBy(get(res, 'data.data'), ['created_at']).reverse()
          );
        } 
      })
      .catch((err) => {
        global.store.dispatch({
          type: "set_loading_show",
          data: { show: false },
        });
      });
  };
  useEffect(() => {
    getProject(); 
    getBuilding();
    getOwneradmin();
  }, []);

  return (
    <div className="theme-2-content">
      <Card margin="20px 0">
        <Row gutter={[0, 0]}>
          {projectInfo && (
            <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
              <Slideshow />
            </Col>
          )}
          <Col xxl={7} xl={7} lg={7} md={11} sm={24} xs={24}>
            {projectInfo && (
              <>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'Project name : \xa0'}</p>
                    <p className="theme-2-text-4">{get(projectInfo, 'name')}</p>
                  </Space>
                </div>
                <div>
                  <Space>
                    <p className="theme-2-text-3">{'Telephone : \xa0'}</p>
                    <p className="theme-2-text-4">
                      {get(projectInfo, 'phone')}
                    </p>
                  </Space>
                </div>
                <div>
                  <p className="theme-2-text-3">{'Project Owner : \xa0'}</p>
                  <div style={{ marginBottom: '10px' }}>
                    <UserCard 
                      user={{
                        id: get(ownerAdminInfo, 'id'),
                        firstname: get(ownerAdminInfo, 'firstname'),
                        lastname: get(ownerAdminInfo, 'lastname'),
                        email: get(ownerAdminInfo, 'email'),
                        telephone_number: get(ownerAdminInfo, 'phone'),
                        face_img: get(ownerAdminInfo, 'portrait_photo'),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {checkPermission("1") && (
              <Row justify="center" gutter={[0, 8]}>
                <Col span={24}>
                  <Button
                    className="theme-2-button-3"
                    block
                    icon={<Icon margin="0 10px 0 0" icon={faEdit} />}
                    onClick={() => {
                         
                       history.push('/editproject');
                    }}
                  >
                    Edit Project
                  </Button>
                </Col>
              </Row>)}
          </Col>
          {projectInfo && (
            <Col offset={1} xxl={16} xl={16} lg={16} md={12} sm={0} xs={0}>
              <Slideshow />
            </Col>
          )}
        </Row>
      </Card>
      <Card>
        <Row justify="space-between">
          <Col xs={12}>
            <p className="theme-2-text-1">{'All Building \xa0'}</p>
            <p className="theme-2-text-2">{`${get(buiding, 'length') ? get(buiding, 'length') : 0
              } Total Building`}</p>
          </Col>
          {checkPermission('1') && (
            <Col xs={0} sm={8} style={{ textAlign: 'right', marginTop: '2%' }}>
              <Button
                type="primary"
                className="theme-2-button-1"
                icon={<Icon margin="0 10px 0 0" color="white" icon={faPlus} />}
                onClick={() => {
                  history.push('/createbuilding');
                }}
              >
                Create Building
              </Button>
            </Col>
          )}
            <Col xs={2} sm={0} style={{ textAlign: 'right', marginTop: '6%' }}>
              <Tooltip title="Create Building" color={theme.primaryColor}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<Icon color="white" icon={faPlus} />}
                  onClick={() => {
                    history.push('/createbuilding');
                  }}
                />
              </Tooltip>
            </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={24} md={18}>
            <CardTable
              rowKey="building_id"
              columns={columns}
              dataSource={buiding}
              bordered={false}
              pagination={{ position: ['bottomCenter'] }}
              size="middle"
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}
